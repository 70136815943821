import React from "react";
import { shallow } from "zustand/shallow";

import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { LoginInfo, User } from "../../../odata/api";
import UserSelectionDialog from "../../dialogs/UserSelectionDialog";
import { useActivityStore } from "../state";

const UserElement = React.memo(() => {
  const { login, user, setUser } = useActivityStore(
    (state) => ({
      login: state.login as LoginInfo,
      user: state.activity?.User,
      setUser: state.setUser,
    }),
    shallow
  );

  const [userSelectionDialogOpen, setUserSelectionDialogOpen] =
    React.useState(false);
  const handleUserSelectionConfirm = (user: User) => {
    setUserSelectionDialogOpen(false);
    user && setUser(user);
  };

  return (
    <Grid item xs={12} sm={6}>
      <TextField
        required
        variant="standard"
        label="Zugeordnet zu"
        value={user?.UserName || user?.UserCode || ""}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                title="Nachschlagen"
                onClick={() => setUserSelectionDialogOpen(true)}
                size="large"
              >
                <MoreHorizIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <UserSelectionDialog
        login={login}
        open={userSelectionDialogOpen}
        onConfirm={handleUserSelectionConfirm}
        onAbort={() => setUserSelectionDialogOpen(false)}
      />
    </Grid>
  );
});

export default UserElement;
