import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

type Result = "ok" | "cancel";

type AlertDialogProps = {
  title: string;
  open: boolean;
  onClose: (result: Result) => void;
  confirmText?: string;
  cancelText?: string;
  disableCancel?: boolean;
  children?: React.ReactNode;
};

const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  open,
  onClose,
  confirmText = "OK",
  cancelText = "Abbrechen",
  children,
  disableCancel = false,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          // Do not close when clicking into the area outside the dialog.
          if (reason === "backdropClick") return;
          onClose("cancel");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {!disableCancel && (
            <Button onClick={() => onClose("cancel")} color="secondary">
              {cancelText}
            </Button>
          )}
          <Button onClick={() => onClose("ok")} color="primary" autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
