import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Activity, Priority } from "../../../odata/api";

const PriorityField = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    return (
      <Grid item xs={12} md={4} sm={12}>
        <TextField
          required
          variant="standard"
          label="Priorität"
          fullWidth
          select
          value={activity.Priority || ""}
          onChange={(x) =>
            setActivity({
              ...activity,
              Priority: x.target.value as Priority,
            })
          }
        >
          <MenuItem value="pr_Low">Niedrig</MenuItem>
          <MenuItem value="pr_Normal">Normal</MenuItem>
          <MenuItem value="pr_High">Hoch</MenuItem>
        </TextField>
      </Grid>
    );
  }
);

export default PriorityField;
