import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Page from "./Page";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  DeliverableOrder,
  LoginInfo,
  getDeliverableOrders,
} from "../../odata/api";
import { useAsync } from "react-use";
import { useState } from "react";

export const OrdersPage: React.FC<{ login: LoginInfo }> = ({ login }) => {
  const [rows, setRows] = useState<DeliverableOrder[] | undefined>(undefined);
  useAsync(async () => {
    const [count, data] = await getDeliverableOrders(login);
    console.assert(
      count == data.length,
      "Die Anzahl der zurückgegebenen Werte stimmt nicht mit der Anzahl der tatsächlichen Werte überein."
    );
    setRows(data);
    return () => {
      setRows(undefined);
    };
  }, [login]);
  return (
    <Page title={`hwwork.app: Neue Aktivität`}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">KA</TableCell>
              <TableCell>Kundenname</TableCell>
              <TableCell>Datum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!rows ? (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  Lade...
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRow key={row.DocNum}>
                  <TableCell align="right" component="th" scope="row">
                    <Link
                      component={RouterLink}
                      color="inherit"
                      variant="inherit"
                      to={`/orders/${row.DocEntry}`}
                      underline="hover"
                    >
                      {row.DocNum}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      color="inherit"
                      variant="inherit"
                      to={`/orders/${row.DocEntry}`}
                      underline="hover"
                    >
                      {row.CardName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {new Date(row.DocDate).toLocaleDateString("de")}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};
