import React from "react";

import { LoginInfo } from "../../odata/api";
import ActivityForm from "../activity/ActivityForm";
import Page from "./Page";

export const NewActivityPage: React.VFC<{ login: LoginInfo }> = ({ login }) => {
  return (
    <Page title={`hwwork.app: Neue Aktivität`}>
      <ActivityForm activityId={undefined} login={login} />
    </Page>
  );
};
