import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { LoginInfo } from "./odata/api";

export const loginState = atomWithStorage<LoginInfo | undefined>(
  "login-info",
  undefined
);

export const handledByState = atom<number | undefined>(undefined);

export const isSmallWidthState = atom(false);

export const settingsState = atomWithStorage("settings", {
  showAllUsers: false,
});
