import React from "react";
import { Grid, TextField } from "@mui/material";
import { Activity } from "../../../odata/api";

const FaxField = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    return (
      <Grid item xs={12} sm={6}>
        <TextField
          variant="standard"
          label="Fax"
          value={activity.Fax || ""}
          onChange={(x) =>
            setActivity({
              ...activity,
              Fax: x.target.value || null,
            })
          }
          fullWidth
        />
      </Grid>
    );
  }
);

export default FaxField;
