import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import ActivitiesIcon from "@mui/icons-material/FormatListBulleted";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { BusinessPartner, LoginInfo } from "../../../odata/api";
import { getPrimaryBusinessPartnerInformation } from "../../../odata/utils";
import CustomerActivitiesDialog from "../../dialogs/CustomerActivitiesDialog";
import CustomerSelectionDialog from "../../dialogs/CustomerSelectionDialog";
import { useActivityStore } from "../state";

const BusinessPartnerElement = React.memo(() => {
  const { businessPartner, setBusinessPartner, login } = useActivityStore(
    (state) => ({
      login: state.login as LoginInfo,
      businessPartner: state.activity?.BusinessPartner as BusinessPartner,
      setBusinessPartner: state.setBusinessPartner,
    }),
    shallow
  );

  const [customerActivitiesDialogOpen, setCustomerActivitiesDialogOpen] =
    useState(false);
  const [customerSelectionDialogOpen, setCustomerSelectionDialogOpen] =
    useState(false);
  const handleCustomerSelectionConfirm = React.useCallback(
    (businessPartner: BusinessPartner) => {
      setCustomerSelectionDialogOpen(false);
      setBusinessPartner(businessPartner);
    },
    [setCustomerSelectionDialogOpen, setBusinessPartner]
  );
  const handleCustomerActivitiesDialogClose = React.useCallback(() => {
    setCustomerActivitiesDialogOpen(false);
  }, [setCustomerActivitiesDialogOpen]);

  return (
    <Grid item xs={12} sm={6}>
      <TextField
        required
        variant="standard"
        label="Kunde"
        value={
          businessPartner
            ? getPrimaryBusinessPartnerInformation(businessPartner)
            : ""
        }
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                title="Zeige die letzten Aktivitäten"
                onClick={() => setCustomerActivitiesDialogOpen(true)}
                size="large"
              >
                <ActivitiesIcon />
              </IconButton>
              <IconButton
                title="Nachschlagen"
                onClick={() => setCustomerSelectionDialogOpen(true)}
                size="large"
              >
                <MoreHorizIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CustomerSelectionDialog
        login={login}
        open={customerSelectionDialogOpen}
        onConfirm={handleCustomerSelectionConfirm}
        onAbort={() => setCustomerSelectionDialogOpen(false)}
      />
      <CustomerActivitiesDialog
        businessPartner={businessPartner || undefined}
        open={customerActivitiesDialogOpen}
        onClose={handleCustomerActivitiesDialogClose}
      />
    </Grid>
  );
});

export default BusinessPartnerElement;
