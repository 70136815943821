import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDeepCompareEffect } from "react-use";

import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import * as colors from "@mui/material/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";

import { Activity, LoginInfo, Priority } from "../../odata/api";
import { LoadInformation } from "../pages/Page";
import { useFetchActivities } from "./hooks";
import { useNavigate, useNavigationType } from "react-router";
import { makeStyles } from "@mui/styles";
import { getBackgroundColor } from "../../odata/utils";

type ActivitiesListProps = {
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  login: LoginInfo;
  setLogin: (login: LoginInfo) => void;
  handledBy: number | undefined;
  refresh: number;
  onRefresh: () => void;
};

function getPriorityColor(priority: Priority) {
  switch (priority) {
    case "pr_Low":
      return colors.blue[500];
    case "pr_Normal":
      return undefined;
    case "pr_High":
      return colors.deepOrange[500];
  }
}

const useStyles = makeStyles((theme: any) => {
  console.log(theme);
  return {
    highPriority: {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
  };
});

const ActivitiesList: React.VFC<ActivitiesListProps> = ({
  filterModel,
  sortModel,
  login,
  setLogin,
  handledBy,
  refresh,
  onRefresh,
}) => {
  const classes = useStyles();

  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const [cursor, setCursor] = useState<number>(0);

  const { activities, activitiesTotalCount } = useFetchActivities(
    login,
    setLogin,
    cursor,
    20,
    filterModel,
    sortModel,
    handledBy,
    refresh
  );
  const [activitiesInternal, setActivitiesInternal] = React.useState<
    Activity[]
  >([]);

  // Reset on unmount
  useEffect(
    () => () => {
      setActivitiesInternal([]);
      setCursor(0);
    },
    []
  );

  // Reset if one of the parameters changes
  useDeepCompareEffect(() => {
    setActivitiesInternal([]);
  }, [handledBy, login, filterModel, sortModel, refresh]);

  // Fix the 'bug' where when the history
  // is going back the `next` function
  // of the infinite scroll component
  // get's called and does not start at page 0.
  useEffect(() => {
    if (navigationType === "PUSH") {
      setCursor(-1);
    }
  }, [navigationType]);

  // Set internal acitivites if activities changes
  useDeepCompareEffect(() => {
    setActivitiesInternal((activitiesInternal) =>
      [...activitiesInternal, ...activities].reduce(
        (unique: Activity[], item: Activity) =>
          unique.filter((x) => x.ActivityCode === item.ActivityCode).length > 0
            ? unique
            : [...unique, item],
        []
      )
    );
  }, [activities]);

  function refreshHandler() {
    setActivitiesInternal([]);
    setCursor(0);
    onRefresh();
  }

  function fetchData() {
    setCursor((cursor) => cursor + 1);
  }

  const handleActivitySelection = (id: number | null) => {
    setCursor(0);
    navigate(`/${id}`);
  };

  const hasMore = React.useMemo(
    () =>
      activitiesInternal.length === 0 ||
      activitiesInternal.length < activitiesTotalCount,
    [activitiesInternal, activitiesTotalCount]
  );

  return (
    <div>
      <InfiniteScroll
        dataLength={activitiesInternal.length}
        next={fetchData}
        hasMore={hasMore}
        loader={<LoadInformation />}
        refreshFunction={refreshHandler}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <Typography sx={{ textAlign: "center" }} variant="body1">
            &#8595; Zum Aktualisiseren nach unten streichen
          </Typography>
        }
        releaseToRefreshContent={
          <Typography sx={{ textAlign: "center" }} variant="body1">
            &#8593; Zum Aktualisieren loslassen
          </Typography>
        }
      >
        {activitiesInternal.map((activity) => (
          <ListItem
            key={activity.ActivityCode}
            disablePadding
            className={
              activity.Priority === "pr_High" ? classes.highPriority : ""
            }
          >
            <ListItemButton
              onClick={() => handleActivitySelection(activity.ActivityCode)}
            >
              <ListItemIcon>
                <Box
                  component={AssignmentIcon}
                  sx={{
                    color: getPriorityColor(activity.Priority),
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={`${new Date(activity.ActivityDate).toLocaleDateString(
                  "de"
                )} – ${activity.Details || "[Details nicht ausgefüllt]"}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline", color: "text.primary" }}
                      component="span"
                      variant="body2"
                    >
                      {" "}
                      {activity.BusinessPartner?.CardName}
                      {" – "}
                      {activity.User?.UserName ||
                        activity.User?.UserCode ||
                        ""}{" "}
                      {" – "}
                      {activity.ActivityType2?.Name}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ActivitiesList;
