import { useAtom } from "jotai";
import React from "react";

import { Theme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid";

import { LoginInfo, Priority } from "../../odata/api";
import { useFetchActivities } from "./hooks";
import { columnsTemplate, pageNumberState, pageSizeState } from "./state";
import { getBackgroundColor } from "../../odata/utils";

type ActivityGridProps = {
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  setFilterModel: (filterModel: GridFilterModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  login: LoginInfo;
  setLogin: (login: LoginInfo) => void;
  handledBy: number | undefined;
  refresh: number;
};

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .activity--High": {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode
    ),
  },
}));

const ActivitiesGrid: React.VFC<ActivityGridProps> = ({
  filterModel,
  sortModel,
  setFilterModel,
  setSortModel,
  login,
  setLogin,
  handledBy,
  refresh,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const [pageSize, setPageSize] = useAtom(pageSizeState);
  const [pageNumber, setPageNumber] = useAtom(pageNumberState);
  const { activities, activitiesTotalCount, loading } = useFetchActivities(
    login,
    setLogin,
    pageNumber,
    pageSize,
    filterModel,
    sortModel,
    handledBy,
    refresh
  );

  const columns = React.useMemo(() => {
    return columnsTemplate.map((column) => {
      if (column.field === "Details")
        return { ...column, flex: isMobile ? undefined : 1 };
      else return column;
    });
  }, [isMobile]);

  const columnVisibityModel: GridColumnVisibilityModel = React.useMemo(() => {
    return Object.fromEntries(
      columnsTemplate.map(({ field, hide }) => [field, !hide || false])
    );
  }, [isMobile]);

  // Needed because the free version of the grid only supports sorting on a single column.
  const sortModelSingle = React.useMemo(
    () => (sortModel.length > 0 ? [sortModel[0]] : []),
    [sortModel]
  );

  return (
    <StyledDataGrid
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={(filterModel) => setFilterModel(filterModel)}
      sortingMode="server"
      sortModel={sortModelSingle}
      onSortModelChange={(sortModel) => setSortModel(sortModel)}
      // pageSizeOptions={[pageSize]}
      pageSizeOptions={[5, 10, 15, 20 /*, 25, 50, 100*/]}
      rowCount={activitiesTotalCount}
      pagination
      // autoPageSize
      paginationMode="server"
      // page={pageNumber}
      paginationModel={{ pageSize, page: pageNumber }}
      // onPageChange={(pageNumber: any) => setPageNumber(pageNumber)}
      // onPageSizeChange={(pageSize: any) => setPageSize(pageSize)}
      onPaginationModelChange={(model) => {
        setPageNumber(model.page);
        setPageSize(model.pageSize);
      }}
      columns={columns}
      columnVisibilityModel={columnVisibityModel}
      rows={activities}
      loading={loading}
      getRowClassName={(params) => {
        return (params.row.Priority as Priority) === "pr_High"
          ? "activity--High"
          : "";
      }}
    />
  );
};

export default ActivitiesGrid;
