import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Activity, ActivityType } from "../../../odata/api";

const ActivityTypeField = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    return (
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          required
          variant="standard"
          label="Aktivität"
          value={activity.Activity || "cn_Other"}
          onChange={(x) =>
            setActivity({
              ...activity,
              Activity: x.target.value as ActivityType,
            })
          }
          fullWidth
          select
        >
          <MenuItem value="cn_Task">Aufgabe</MenuItem>
          <MenuItem value="cn_Campaign">Kampagne</MenuItem>
          <MenuItem value="cn_Meeting">Besprechung</MenuItem>
          <MenuItem value="cn_Note">Notiz</MenuItem>
          <MenuItem value="cn_Conversation">Telefonat</MenuItem>
          <MenuItem value="cn_Other">Sonstige</MenuItem>
        </TextField>
      </Grid>
    );
  }
);

export default ActivityTypeField;
