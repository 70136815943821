import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { useLocation } from "react-router-dom";
import { useAsync, useCookie, useLocalStorage } from "react-use";

import { LOGIN_VERSION } from "./constants";
import { login as loginF, LoginInfo } from "./odata/api";
import { handledByState } from "./state";

export function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export function useChangeDetection<T, K = T>(
  object: T | undefined,
  keyExtractor?: (object: T) => K
) {
  const [originalObject, setOriginalObject] = useState<T | undefined>(
    undefined
  );
  const [dirty, setDirty] = useState(false);
  const key = keyExtractor && object ? keyExtractor(object) : object;

  useMemo(() => {
    if (!originalObject) setOriginalObject(object);
  }, [object, originalObject]);

  useEffect(() => {
    if (!object) {
      setDirty(false);
    } else {
      const equals = isEqual(object, originalObject);
      setDirty(!equals);
    }
  }, [object, originalObject]);

  useEffect(() => {
    setDirty(false);
  }, [key]);

  return dirty;
}

export function useLoginManagement() {
  const [login, setLogin] = useLocalStorage<LoginInfo>("login-info", undefined);
  const [b1SessionCookie, updateb1SessionCookie] = useCookie("B1SESSION");
  const [, setHandledBy] = useAtom(handledByState);

  // Migrate old login version.
  if (login && (!login.version || login.version < LOGIN_VERSION)) {
    setLogin(null as any as undefined);
  }

  React.useEffect(() => {
    if (login?.sessionId && b1SessionCookie !== login.sessionId) {
      updateb1SessionCookie(login.sessionId, {
        secure: true,
        doamin: "hwwork.app",
        path: "/",
        sameSite: "None",
      });
    }
  }, [b1SessionCookie, login?.sessionId, updateb1SessionCookie]);

  useAsync(async () => {
    if (login?.username && !b1SessionCookie) {
      await loginF(login, true);
    }
  }, [login?.username, login, b1SessionCookie]);

  React.useEffect(() => {
    setHandledBy(login?.userInfo?.internalKey);
  }, [login?.userInfo?.internalKey, setHandledBy]);
}
