import React, { Suspense, useEffect, useState } from "react";
import { useAsync, useDebounce } from "react-use";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Alert } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";

import {
  BusinessPartner,
  getBusinessPartners,
  LoginInfo,
} from "../../odata/api";
import {
  getPrimaryBusinessPartnerInformation,
  getSecondaryBusinessPartnerInformation,
} from "../../odata/utils";
import BusinessPartnerAvatar from "../misc/BusinessPartnerAvatar";
import { LoadInformation } from "../pages/Page";

type CustomerSelectionDialogProps = {
  login: LoginInfo;
  open: boolean;
  onConfirm: (businessPartner: BusinessPartner) => void;
  onAbort: () => void;
};

const CustomerSelectionDialog: React.VFC<CustomerSelectionDialogProps> = ({
  login,
  open: openProp,
  onConfirm,
  onAbort,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(openProp);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCardCode, setSelectedCardCode] = useState<
    string | undefined
  >();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  useDebounce(
    () => {
      setDebouncedSearchText(searchText);
    },
    500,
    [searchText]
  );
  const [businessPartners, setBusinessPartners] = useState<
    BusinessPartner[] | undefined
  >();

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  useAsync(async () => {
    if (open) {
      const [, businessPartners] = await getBusinessPartners(login, 0, 20, {
        $filter: `contains(CardName, '${debouncedSearchText}') or startswith(CardCode, '${debouncedSearchText}')`,
      });
      setBusinessPartners(businessPartners);
    } else {
      setBusinessPartners(undefined);
      setSelectedCardCode(undefined);
      setSearchText("");
      setDebouncedSearchText("");
    }
  }, [login, open, debouncedSearchText]);

  function handleConfirm() {
    setOpen(false);
    const businessPartners_ =
      businessPartners?.filter((bp) => bp.CardCode === selectedCardCode) || [];
    if (businessPartners_?.length > 0) {
      onConfirm(businessPartners_[0]);
    } else {
      alert("Kunde nicht gefunden.");
    }
  }
  function handleAbort() {
    setOpen(false);
    onAbort();
  }

  return (
    <Dialog
      open={open}
      onClose={(e, x) => {
        onAbort();
      }}
      fullWidth
      fullScreen={isFullScreen}
      maxWidth="md"
    >
      <DialogTitle style={{ background: theme.palette.primary.main }}>
        Kunden {selectedCardCode && ` - ${selectedCardCode}`}
      </DialogTitle>
      <DialogContent dividers>
        <ErrorBoundary>
          <DialogContentText>Wähle den gewünschten Kunden:</DialogContentText>
          <Suspense fallback={<LoadInformation />}>
            <TextField
              variant="standard"
              label="Suche"
              autoFocus
              placeholder="Firmenname oder Nummer"
              fullWidth
              value={searchText}
              onChange={(x) => setSearchText(x.target.value)}
            />
            <List>
              {businessPartners ? (
                businessPartners.map((businessPartner) => (
                  <ListItem
                    key={businessPartner.CardCode}
                    button
                    selected={selectedCardCode === businessPartner.CardCode}
                    onClick={() =>
                      setSelectedCardCode(businessPartner.CardCode)
                    }
                    onDoubleClick={() => {
                      setSelectedCardCode(businessPartner.CardCode);
                      handleConfirm();
                    }}
                  >
                    <ListItemAvatar
                      style={
                        businessPartner.Valid === "tNO" ? { opacity: 0.7 } : {}
                      }
                    >
                      <BusinessPartnerAvatar
                        businessPartner={businessPartner}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={
                        businessPartner.Valid === "tNO" ? { opacity: 0.7 } : {}
                      }
                      primary={getPrimaryBusinessPartnerInformation(
                        businessPartner
                      )}
                      secondary={getSecondaryBusinessPartnerInformation(
                        businessPartner
                      )}
                    />
                  </ListItem>
                ))
              ) : (
                <LoadInformation />
              )}
            </List>
            {businessPartners && businessPartners.length >= 20 && (
              <Alert severity="info">
                Anzahl der Kunden über die Suche eingrenzen...
              </Alert>
            )}
          </Suspense>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAbort()}>Abbrechen</Button>
        <Button onClick={() => handleConfirm()} disabled={!selectedCardCode}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerSelectionDialog;
