import { User } from "@sentry/types";
import isEqual from "react-fast-compare";
import { createWithEqualityFn } from "zustand/traditional";
import { combine } from "zustand/middleware";
import { shallow } from "zustand/shallow";

import {
  Activity,
  Article,
  BusinessPartner,
  DocType,
  DocumentLine,
  DocumentSpecialLine,
  findBusinessPartnerByPhoneNumber,
  getActivity,
  getOrder as getOrderF,
  LoginInfo,
  Order,
  saveActivity as saveActivityF,
  saveOrder as saveOrderF,
  sendMail,
  uploadAttachment,
} from "../../odata/api";
import {
  boolean2SAPBoolean,
  calculateDuration,
  calculateQuantity,
  dataURIToBlob,
  getItemCode,
  getLineText,
  isOnSiteActivity,
  isRemoteMaintenanceActivity,
  sortDocumentSpecialLinesAfterDocumentLines,
} from "../../odata/utils";

function initActivityStore() {
  return {
    login: getLocalStorage<LoginInfo>("login-info"),
    activityId: undefined as number | undefined,
    activity: undefined as Activity | undefined,
    order: undefined as Order | undefined,
    remote: {
      activity: undefined as Activity | undefined,
      order: undefined as Order | undefined,
    },
    dirty: false as boolean,
    busy: false as boolean,
    phoneNumber: undefined as string | undefined,
    previousActivity: undefined as Activity | undefined,
  };
}

export const useActivityStore = createWithEqualityFn(
  combine(initActivityStore(), (set, get) => ({
    async load(activityId?: number, phoneNumber?: string) {
      try {
        set(() => ({ busy: true }));
        const { activityId: oldActivityId, login } = get();
        if (!login) {
          // If no login exists, reset the store
          set(() => initActivityStore());
          return;
        }
        if (activityId && oldActivityId !== activityId) {
          // For an existing activity:
          const activity = await getActivity(login, activityId);
          const order = (await getOrder(login, activity)) || undefined;
          set(() => ({
            dirty: false,
            activityId,
            activity,
            order,
            phoneNumber,
            remote: { activity, order },
          }));
        } else if (activityId === undefined) {
          // For a new activity:
          const emptyActivity = createEmptyActivity(login);
          const activity = await enrichWithBusinessPartner(
            login,
            emptyActivity,
            phoneNumber
          );
          // Check if it is a on site activity,
          // if so, create a new empty order,
          // else do not do anything with the order.
          const order = isOnSiteActivity(login, activity)
            ? createEmptyOrder(login, activity)
            : undefined;
          set(() => ({
            dirty: false,
            busy: false,
            activityId: undefined,
            activity,
            order,
            phoneNumber,
          }));
        }
      } finally {
        set(() => ({ busy: false }));
      }
      const { loadPreviousActivity } = get() as ReturnType<typeof get> &
        typeof this;
      await loadPreviousActivity();
    },
    async save(closeActivity?: boolean) {
      try {
        set(() => ({ busy: true }));

        const {
          login,
          activityId: existingActivityId,
          activity: existingActivity,
          order: existingOrder,
          previousActivity,
          remote: { activity: remoteActivity },
        } = get();
        // In order to save the user must be logged
        // in and have an existing activity.
        if (login && existingActivity) {
          const activityId =
            existingActivityId === undefined
              ? (await saveActivity(login, undefined, existingActivity, false))
                  .ActivityCode! // ActivityCode cannot be null after saving a new activity
              : existingActivityId;
          // The `activityOrderInformation` is information about the order
          // that has to be used for the activity when saving so the
          // activity can later reference the order.
          const [order, activityOrderInformation] = await saveOrder(
            login,
            { ...existingActivity, ActivityCode: activityId },
            existingOrder,
            closeActivity
          );
          const activity = await saveActivity(
            login,
            activityId,
            {
              ...existingActivity,
              ActivityCode: activityId,
              PreviousActivity: previousActivity?.ActivityCode || null,
            },
            closeActivity,
            activityOrderInformation
          );
          // Merge the existing order an activity with
          // the new data from the REST API
          const mergedActivity = { ...existingActivity, ...activity };
          const mergedOrder = { ...existingOrder, ...order };
          // Send Mail if Reminder is set to true
          if (
            (remoteActivity?.Reminder === "tNO" || !remoteActivity) &&
            mergedActivity.Reminder === "tYES" &&
            mergedActivity.User?.eMail
          ) {
            const url = `https://hwwork.app/${
              mergedActivity.ActivityCode || activityId
            }`;
            const mailMessage = {
              subject: "Eine neue Aufgabe wurde für dich angelegt",
              body: `Unter folgendem Link findest du die Aufgabe: ${url}`,
              recipients: [
                {
                  name: mergedActivity.User?.UserName || "",
                  emailAddress: mergedActivity.User?.eMail,
                },
              ],
            };
            if (import.meta.env.PROD) {
              await sendMail(mailMessage);
            } else {
              alert(
                "Folgende E-Mail würde jetzt versendet:\n\n" +
                  JSON.stringify(mailMessage, null, " ")
              );
            }
          }
          set({
            dirty: false,
            order: mergedOrder,
            activityId,
            activity: mergedActivity,
            remote: {
              order: mergedOrder,
              activity: mergedActivity,
            },
          } as any);
        }
      } catch (error) {
        alert(
          `Ein Fehler ist beim Speichern der Aktivität aufgetreten.\n\n${error}`
        );
      } finally {
        set(() => ({ busy: false }));

        const { activityId } = get();
        return activityId;
      }
    },
    reset() {
      set(() => initActivityStore());
    },
    setActivity(activity: Activity) {
      const { remote, order } = get();
      const dirty = checkIfDirty(activity, order, remote);
      set(() => ({ activity, dirty }));
    },
    async setPreviousActivity(activityId: number) {
      const { activity } = get();
      if (!activity) return;
      const newActivity = { ...activity, PreviousActivity: activityId };
      set(() => ({ activity: newActivity }));
    },
    unsetPreviousActivity() {
      const { activity } = get();
      const newActivity = activity
        ? { ...activity, PreviousActivity: null }
        : undefined;
      set(() => ({ activity: newActivity, previousActivity: undefined }));
    },
    async loadPreviousActivity() {
      const { activity, login } = get();
      if (login && activity && activity.PreviousActivity) {
        const previousActivity = await getActivity(
          login,
          activity.PreviousActivity
        );
        set(() => ({ previousActivity }));
      }
    },
    setOrder(order: Order) {
      const { activity, remote } = get();
      const dirty = checkIfDirty(activity, order, remote);
      set(() => ({ order, dirty }));
    },
    addArticleToOrder(
      customerOrder: Order | undefined,
      article: Article,
      serialNumber?: string
    ) {
      set(
        (state) =>
          ({
            ...state,
            order: addArticleToOrder(customerOrder, article, serialNumber),
          } as any)
      );
    },
    setOrderDocumentLineQuantity(documentLine: DocumentLine, quantity: number) {
      const { setOrder, order } = get() as ReturnType<typeof get> & typeof this;
      setOrder({
        ...order,
        DocumentLines: order?.DocumentLines.map((dl) =>
          dl.LineNum === documentLine.LineNum
            ? {
                ...dl,
                Quantity: quantity,
              }
            : dl
        ),
      } as Order);
    },
    deleteOrderDocumentLineSerialNumber(
      documentLine: DocumentLine,
      sn: { InternalSerialNumber: string }
    ) {
      const { setOrder, order } = get() as ReturnType<typeof get> & typeof this;
      setOrder({
        ...order,
        DocumentLines: order?.DocumentLines.map((dl) => {
          const serialNumbers = dl.SerialNumbers.filter(
            (sn2) => sn.InternalSerialNumber !== sn2.InternalSerialNumber
          );
          return dl.ItemCode === documentLine.ItemCode
            ? {
                ...dl,
                Quantity: serialNumbers.length,
                SerialNumbers: serialNumbers,
              }
            : dl;
        }),
      } as Order);
    },
    deleteOrderDocumentLine(documentLine: DocumentLine) {
      const { setOrder, order } = get() as ReturnType<typeof get> & typeof this;
      setOrder({
        ...order,
        DocumentLines: order?.DocumentLines.filter(
          (a) => a.ItemCode !== documentLine.ItemCode
        ),
      } as Order);
    },
    setBusinessPartner(businessPartner: BusinessPartner) {
      const { setActivity, activity } = get() as ReturnType<typeof get> &
        typeof this;
      setActivity({
        ...activity,
        CardCode: businessPartner.CardCode,
        BusinessPartner: {
          CardCode: businessPartner.CardCode,
          CardName: businessPartner.CardName,
          CardType: businessPartner.CardType,
          Valid: businessPartner.Valid,
        },
        Phone: businessPartner.Phone1,
      } as Activity);
    },
    setUser(user: User) {
      const { setActivity, activity } = get() as ReturnType<typeof get> &
        typeof this;
      setActivity({
        ...activity,
        HandledBy: user.InternalKey,
        User: {
          UserName: user.UserName,
          UserCode: user.UserCode,
          FaxNumber: user.FaxNumber,
          eMail: user.eMail,
        },
      } as Activity);
    },
    async saveSignatureData({
      name,
      email,
      signature,
    }: {
      name: string;
      email?: string;
      signature: string;
    }) {
      const { activity } = get();

      const signatureInfoLines = [
        {
          LineNum: 0,
          AfterLineNumber: 0,
          OrderNumber: 1,
          LineText: `SIGNATURE_NAME: ${name}`,
          LineType: "dslt_Text",
        },
        email
          ? {
              LineNum: 1,
              AfterLineNumber: 0,
              OrderNumber: 1,
              LineText: `SIGNATURE_EMAIL: ${email}`,
              LineType: "dslt_Text",
            }
          : undefined,
        {
          LineNum: email ? 1 : 2,
          AfterLineNumber: 1,
          OrderNumber: 1,
          LineType: "dslt_Text",
          LineText: getLineText(activity!),
        },
        // {
        //   LineNum: 2,
        //   AfterLineNumber: 1,
        //   OrderNumber: 1,
        //   LineText: `SIGNATURE_DATA: ${signature}`,
        //   LineType: "dslt_Text",
        // },
      ].filter((x) => x !== undefined) as DocumentSpecialLine[];
      const { login, order: originalOrder } = get();

      // Upload Signature as an Attachment
      const blob = dataURIToBlob(signature);
      const dateString = new Date().toISOString().replaceAll(":", "-");
      const filename = `signature-${originalOrder?.DocEntry!}-${dateString}.png`;
      const { id: attachmentEntry } = await uploadAttachment(
        login!,
        blob,
        filename
      );

      const orderToSave = {
        DocumentSpecialLines: sortDocumentSpecialLinesAfterDocumentLines(
          signatureInfoLines,
          originalOrder?.DocumentLines
        ),
        AttachmentEntry: attachmentEntry,
      } as Order;
      const updatedOrder = await saveOrderF(
        login!,
        originalOrder!.DocEntry!,
        orderToSave
      );
      const { order, setOrder } = get() as ReturnType<
        typeof initActivityStore
      > &
        typeof this;
      setOrder({
        ...order,
        AttachmentEntry: attachmentEntry,
        DocumentSpecialLines: [...updatedOrder.DocumentSpecialLines!],
      } as Order);

      // Do not mark as dirty
      set(() => ({ dirty: false }));
    },
    async closeOnSiteActivity(data: {
      name: string;
      email: string;
      signature: string;
    }) {
      const { save, saveSignatureData } = get() as ReturnType<
        typeof initActivityStore
      > &
        typeof this;
      await saveSignatureData(data);
      await save(true);
    },
  })),
  shallow
);

function addArticleToOrder(
  customerOrder: Order | undefined,
  article: Article,
  serialNumber?: string
) {
  const order: Partial<Order> &
    Pick<Order, "DocumentLines" | "DocumentSpecialLines"> = customerOrder || {
    DocumentLines: [],
    DocumentSpecialLines: [],
  };
  let documentLines = order.DocumentLines || [];
  const existingDocumentLine = documentLines.find(
    (dl) => dl.ItemCode === article.ItemCode
  );
  if (existingDocumentLine) {
    // Document line already exists for the selected `ItemCode`
    const serialNumbers = [
      ...existingDocumentLine.SerialNumbers.filter(
        (sn) => sn.InternalSerialNumber !== serialNumber
      ),
      ...(serialNumber
        ? [
            {
              InternalSerialNumber: serialNumber,
              Quantity: 1.0,
            } as const,
          ]
        : []),
    ];
    const updatedDocumentLine = {
      ...existingDocumentLine,
      Quantity: serialNumber
        ? serialNumbers.length
        : existingDocumentLine.Quantity + 1,
      SerialNumbers: Array.from(serialNumbers),
    };
    documentLines = documentLines.map((dl) =>
      dl.ItemCode === article.ItemCode ? updatedDocumentLine : dl
    );
  } else {
    // Document line does not exist
    const lineNums =
      order.DocumentLines.length > 0
        ? order.DocumentLines.map((dl) => dl.LineNum)
        : [-1];
    const nextLineNum = Math.max(...lineNums) + 1;
    const newDocumentLine = {
      LineNum: nextLineNum,
      ItemCode: article.ItemCode,
      ItemDescription: article.ItemName,
      Quantity: 1,
      SerialNumbers: serialNumber
        ? [
            {
              InternalSerialNumber: serialNumber,
              Quantity: 1.0,
            } as const,
          ]
        : [],
    };
    documentLines = [...documentLines, newDocumentLine];
  }
  return { ...order, DocumentLines: documentLines };
}

async function getOrder(login: LoginInfo, activity: Activity | undefined) {
  if (activity && isOnSiteActivity(login, activity)) {
    if (activity.DocEntry) {
      return await getOrderF(login, Number(activity.DocEntry));
    } else {
      return createEmptyOrder(login, activity);
    }
  }
}

async function saveActivity(
  login: LoginInfo,
  activityId: number | undefined,
  activity: Activity,
  closeActivity: boolean | undefined,
  orderInformation?: { DocType: string; DocNum: string; DocEntry: string }
) {
  const closed = boolean2SAPBoolean(closeActivity);
  // Get end time and duration information
  const endTimeAndDuration = closeActivity
    ? calculateEndTimeAndDuration(activity, closeActivity)
    : undefined;
  return await saveActivityF(login, activityId, {
    ...{
      Activity: activity.Activity,
      ActivityType: activity.ActivityType,
      Priority: activity.Priority,
      CardCode: activity.CardCode,
      Notes: activity.Notes,
      Details: activity.Details,
      HandledBy: activity.HandledBy,
      StartDate: activity.StartDate,
      StartTime: activity.StartTime,
      Phone: activity.Phone,
      Fax: activity.Fax,
      Closed: closed || activity.Closed,
      PreviousActivity: activity.PreviousActivity,
      Reminder: activity.Reminder,
    },
    ...(activity.EndTime ? { EndTime: activity.EndTime } : undefined),
    ...endTimeAndDuration,
    ...orderInformation,
  });
}

async function saveOrder(
  login: LoginInfo,
  activity: Activity,
  order: Order | undefined,
  closeActivity?: boolean
): Promise<
  [
    Partial<Order> | undefined,
    { DocType: string; DocNum: string; DocEntry: string } | undefined
  ]
> {
  if (closeActivity && isRemoteMaintenanceActivity(activity)) {
    // Remote maintenance activity
    const itemCode = getItemCode(activity.User || undefined);
    const quantity = calculateQuantity(activity);
    const orderToSave: Partial<Order> = {
      CardCode: activity.CardCode!,
      DocDate: activity.ActivityDate || new Date().toISOString().substr(0, 10),
      DocDueDate:
        activity.ActivityDate || new Date().toISOString().substr(0, 10),
      NumAtCard: "ZU VERRECHNEN - FERNWARTUNG",
      DocumentLines: [
        {
          LineNum: 0,
          ItemCode: itemCode,
          Quantity: quantity,
          SerialNumbers: [],
        },
      ],
      DocumentSpecialLines: [
        {
          LineNum: 1,
          AfterLineNumber: 0,
          OrderNumber: 1,
          LineType: "dslt_Text",
          LineText: getLineText(activity),
        },
      ],
    };
    const orderSaved = await saveOrderF(login, undefined, orderToSave);
    const activityOrderInformation = {
      DocType: String(DocType.CustomerOrder),
      DocNum: String(orderSaved.DocNum),
      DocEntry: String(orderSaved.DocEntry),
    };
    return [orderSaved, activityOrderInformation];
  } else if (
    (order?.DocumentLines || []).length === 0 &&
    !isRemoteMaintenanceActivity(activity)
  ) {
    // If no document lines are provided do not save the order
    return [undefined, undefined];
  } else if (isOnSiteActivity(login, activity) && order) {
    // On site activity
    const orderId = activity.DocEntry ? Number(activity.DocEntry) : undefined;
    const updatedOrder = await saveOrderF(login, orderId, {
      ...order,
      CardCode: activity.CardCode!,
      DocDueDate:
        activity.ActivityDate || new Date().toISOString().substring(0, 10),
      NumAtCard: closeActivity
        ? "ZU VERRECHNEN – VORORT"
        : order.NumAtCard || "IN BEARBEITUNG - VORORT",
    });
    const activityOrderInformation = {
      DocType: String(DocType.CustomerOrder),
      DocNum: String(updatedOrder.DocNum),
      DocEntry: String(updatedOrder.DocEntry),
    };
    return [updatedOrder, activityOrderInformation];
  } else {
    return [undefined, undefined];
  }
}

function getLocalStorage<T>(key: string): T | undefined {
  const data = window.localStorage.getItem(key);
  return data && JSON.parse(data);
}

function checkIfActivityDirty(newActivity?: Activity, oldActivity?: Activity) {
  return !isEqual(oldActivity, newActivity);
}

function checkIfOrderDirty(newOrder?: Order, oldOrder?: Order) {
  return !isEqual(oldOrder, newOrder);
}

function createEmptyActivity(login: LoginInfo) {
  const startDate = new Date();
  startDate.setTime(
    startDate.getTime() - startDate.getTimezoneOffset() * 60 * 1000
  );
  const endDate = new Date();
  endDate.setHours(1);
  return {
    ActivityCode: null,
    Activity: "cn_Conversation",
    ActivityType: -100,
    ActivityType2: {
      Code: -1,
      Name: "",
    },
    ActivityDate: new Date().toISOString(),
    Priority: "pr_Normal",
    Closed: "tNO",
    CardCode: null,
    BusinessPartner: null,
    HandledBy: null,
    User: {
      UserCode: login.username,
      UserName: login.userInfo?.fullName || null,
      FaxNumber: login.userInfo?.faxNumber || null,
      eMail: login.userInfo?.eMail || null,
    },
    Phone: null,
    Fax: null,
    StartDate: startDate.toISOString().substr(0, 10),
    StartTime: startDate.toISOString().substr(11, 5),
    // EndTime: endDate.toISOString().substr(11, 5),
    EndTime: null,
    Details: null,
    Notes: null,
    DocType: null,
    DocNum: null,
    DocEntry: null,
  } as Activity;
}

function createEmptyOrder(login: LoginInfo, activity: Activity) {
  return {
    DocDate: activity.ActivityDate || new Date().toISOString().substr(0, 10),
    DocDueDate: activity.ActivityDate || new Date().toISOString().substr(0, 10),
    CardCode: activity.CardCode,
    NumAtCard: null,
    DocNum: null,
    DocEntry: null,
    DocumentLines: [],
    DocumentSpecialLines: [],
    AttachmentEntry: null,
  } as Order;
}

function checkIfDirty(
  activity?: Activity,
  order?: Order,
  remote?: { activity?: Activity; order?: Order }
) {
  return (
    checkIfActivityDirty(activity, remote?.activity) ||
    checkIfOrderDirty(order, remote?.order)
  );
}

function calculateEndTimeAndDuration(
  activity: Activity,
  closed: boolean | undefined
) {
  const endTimeAndDuration = calculateDuration(activity, closed);
  if (endTimeAndDuration)
    return {
      EndTime: endTimeAndDuration.endTime,
      Duration: endTimeAndDuration.duration,
      DurationType: endTimeAndDuration.durationType,
    };
}

async function enrichWithBusinessPartner(
  login: LoginInfo,
  activity: Activity,
  phone?: string
) {
  if (phone) {
    const businessPartner = await findBusinessPartnerByPhoneNumber(
      login,
      phone
    );
    if (businessPartner) {
      return {
        ...activity,
        Phone: phone,
        CardCode: businessPartner.CardCode,
        BusinessPartner: {
          CardCode: businessPartner.CardCode,
          CardName: businessPartner.CardName,
          CardType: businessPartner.CardType,
          Valid: businessPartner.Valid,
        },
      };
    }
  }
  return activity;
}
