import humanizeDurationF from "humanize-duration";
import { darken, lighten } from "@mui/material/styles";

import {
  GridColDef,
  GridFilterModel,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";

import { TEST_DATABASE_NAME } from "../constants";
import {
  Activity,
  ActivityCodeEnum,
  BusinessPartner,
  DocumentLine,
  DocumentSpecialLine,
  LoginInfo,
  User,
} from "./api";

export type ODataGridColumn = GridColDef & {
  filterField?: string;
  sortField?: string;
  hide?: boolean;
};

type Field = string;
type Operator = string;
type Value = string;

export function isTestDatabase(login: LoginInfo) {
  return login.database === TEST_DATABASE_NAME;
}

function convertToString(value: any): Value {
  switch (typeof value) {
    case "undefined":
      return "null";
    case "number":
      return String(value);
    case "boolean":
      return !value ? "'tNO'" : "'tYES'";
    case "string":
      switch (value) {
        case "true":
          return convertToString(true);
        case "false":
          return convertToString(false);
      }
      return `'${value}'`;
    default:
      throw new Error(`'${typeof value}' not implemented`);
  }
}

function convertToFunction(
  field: Field,
  operator: string,
  value: Value
): Operator {
  switch (operator) {
    case "contains":
      return `contains(${field}, ${convertToString(value)})`;
    case "=":
      return `${field} eq ${value}`;
    case "equals":
    case "is":
      return `${field} eq ${convertToString(value)}`;
    case "!=":
      return `${field} ne ${value}`;
    case "not":
      return `${field} ne ${convertToString(value)}`;
    case ">":
      return `${field} gt ${value}`;
    case "after":
      return `${field} gt ${convertToString(value)}`;
    case ">=":
      return `${field} ge ${value}`;
    case "onOrAfter":
      return `${field} ge ${convertToString(value)}`;
    case "<":
      return `${field} lt ${value}`;
    case "before":
      return `${field} lt ${convertToString(value)}`;
    case "<=":
      return `${field} le ${value}`;
    case "onOrBefore":
      return `${field} le ${convertToString(value)}`;
    case "startsWith":
      return `startswith(${field}, ${convertToString(value)})`;
    case "endsWith":
      return `endswith(${field}, ${convertToString(value)})`;
    default:
      throw new Error(`'${operator}' not implemented`);
  }
}

function interpretFilter(path: string, operator: string, value: any) {
  return convertToFunction(path, operator, value);
}

function convertToSortDirection(sortDirection: GridSortDirection) {
  switch (sortDirection) {
    case "desc":
      return "desc";
    default:
      return "asc";
  }
}

function interpretSort(field: string, sortDirection: GridSortDirection) {
  return `${field} ${convertToSortDirection(sortDirection)}`;
}

export function getFilterValue(
  columns: ODataGridColumn[],
  params: GridFilterModel
) {
  const filterValues: string[] = [];
  for (let item of params.items) {
    const column = columns.find((c) => c.field === item.field);
    if (!column) {
      console.warn(`Could not find column '${item.field}'.`);
      return;
    }
    const filterField = column?.filterField || column?.field;
    const value = item.value;
    if (column && filterField && value) {
      const operator = item.operator || "equals";
      const filterExpression = interpretFilter(filterField, operator, value);
      filterValues.push(filterExpression);
    } else {
      console.warn("Could not set filter value.");
    }
  }
  return filterValues.join(" and ");
}

export function getSortValue(
  columns: ODataGridColumn[],
  sortModel: GridSortModel
) {
  const sortValues: string[] = [];
  for (let item of sortModel) {
    const column = columns.find((c) => c.field === item.field);
    const sortField = column?.sortField;
    if (column && sortField) {
      const sortDirection = item.sort;
      const sortExpression = interpretSort(sortField, sortDirection);
      sortValues.push(sortExpression);
    }
  }
  return sortValues.join(", ");
}

export function humanizeDuration(durationInMilliseconds: number) {
  return humanizeDurationF(durationInMilliseconds, {
    language: "de",
    fallbacks: ["it", "en"],
  });
}

export function getDuration(duration: number, durationType: string) {
  switch (durationType) {
    case "du_Seconds":
      return humanizeDuration(duration * 1000);
    default:
      return `${duration} ${durationType}`;
  }
}

export function getItemCode(user?: Partial<User>) {
  if (!user?.FaxNumber)
    throw Error(`Could not extract the fax number from ${user}`);
  return user?.FaxNumber;
}

function roundUpToNearestMinutes(minutes: number, minutesToRound: number = 15) {
  return minutesToRound * Math.ceil(minutes / minutesToRound);
}

export function calculateElapsedTime(activity: Partial<Activity>) {
  const startDateStripped = convertDateTimeToDate(activity.StartDate);
  const startDateTime = new Date(`${startDateStripped}T${activity.StartTime}`);
  const date = new Date();
  date.setMilliseconds(0);
  const endDateTime = activity.EndTime
    ? new Date(`${startDateStripped}T${activity.EndTime}`)
    : date;
  const diff = endDateTime.getTime() - startDateTime.getTime();
  const minutes = diff / 1000 / 60;
  return Math.round(minutes);
}

export function calculateQuantity(activity: Partial<Activity>) {
  const minutes = calculateElapsedTime(activity);
  return roundUpToNearestMinutes(minutes, 15) / 60;
}

export function getLineText(activity: Partial<Activity>) {
  const startDateStripped = convertDateTimeToDate(activity.StartDate);
  return `${startDateStripped || ""} ${activity.StartTime || ""}\n${
    activity.Details || ""
  }\n${activity.Notes || ""}`;
}

export function getBusinessPartnerType(cardType?: BusinessPartner["CardType"]) {
  switch (cardType) {
    case undefined:
      return "";
    case "cCustomer":
      return "Kunde";
    case "cSupplier":
      return "Lieferant";
    case "cLid":
      return "Interessent";
    default:
      return "Unbekannt";
  }
}

export function getPrimaryBusinessPartnerInformation(
  businessPartner: Pick<BusinessPartner, "CardName" | "CardType" | "Valid">
) {
  const type = getBusinessPartnerType(businessPartner.CardType);
  return `${businessPartner.CardName}${type ? ` [${type}]` : ""}${
    businessPartner.Valid === "tNO" ? " - Inaktiv" : ""
  }`;
}

export function getSecondaryBusinessPartnerInformation(
  businessPartner: Pick<BusinessPartner, "Address" | "ZipCode" | "City">
) {
  return `${(businessPartner.Address && `${businessPartner.Address}, `) || ""}${
    businessPartner.ZipCode || ""
  } ${businessPartner.City || ""}`;
}

export function boolean2SAPBoolean(closeActivity: boolean | undefined) {
  return closeActivity ? "tYES" : !closeActivity ? "tNO" : undefined;
}

export function isOnSiteActivity(login: LoginInfo, activity?: Activity) {
  return activity?.ActivityType === ActivityCodeEnum.OnSite;
}

export function isRemoteMaintenanceActivity(activity?: Activity) {
  return activity?.ActivityType === ActivityCodeEnum.RemoteMaintenance;
}

export function calculateDuration(
  activity: Activity,
  closed?: boolean
): {
  endTime: string;
  duration: number;
  durationType: Activity["DurationType"];
} | null {
  const date = new Date();
  const endTimeISO = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
  const endTime =
    closed && !activity.EndTime ? endTimeISO.substr(11, 5) : activity.EndTime;

  if (endTime && activity.StartTime && activity.StartDate) {
    const duration = calculateElapsedTime(activity) * 60;
    return { endTime, duration, durationType: "du_Seconds" };
  } else {
    return null;
  }
}

export function dataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export function sortDocumentSpecialLinesAfterDocumentLines(
  documentSpecialLines: DocumentSpecialLine[],
  documentLines?: DocumentLine[]
) {
  if (documentLines && documentLines.length > 0) {
    // The document special lines line number needs to be bigger than the document lines line number.
    const maxLineNum = Math.max(...documentLines.map((dl) => dl.LineNum));
    const afterLineNumCount = documentLines.length;
    return documentSpecialLines.map((dsl, i) => {
      return {
        ...dsl,
        LineNum: maxLineNum + i,
        AfterLineNumber: afterLineNumCount - 1,
      };
    });
  }
  return documentSpecialLines;
}

export function convertDateTimeToDate(date?: string | null) {
  return date?.substring(0, 10);
}

export const getBackgroundColor = (color: string, mode: string) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);
