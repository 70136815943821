import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListSubheader,
  TextField,
  useTheme,
  Divider,
  useMediaQuery,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useActivityStore } from "../activity/state";

type StartAnyDeskSessionDialogProps = {
  open: boolean;
  onAbort: () => void;
};

function extractAnyDeskIds(notes?: string): string[] {
  if (!notes) return [];
  return Array.from(notes.matchAll(/anydesk:(?<anydesk>[\w@\-.]+)/gm))
    .map((match) => match?.groups?.anydesk)
    .filter((x) => x !== undefined)
    .map((x) => x as string);
}

function StartAnyDeskSessionDialog({
  open,
  onAbort,
}: StartAnyDeskSessionDialogProps) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anyDeskId, setAnyDeskId] = useState<string>("");
  const { extractedAnyDeskIds, appendToNotes } = useActivityStore(
    ({ activity, setActivity }) => ({
      extractedAnyDeskIds: extractAnyDeskIds(activity?.Notes || undefined),
      appendToNotes: (notes: string) => {
        activity &&
          setActivity({
            ...activity,
            Notes: activity.Notes ? `${activity.Notes}\n\n${notes}` : notes,
          });
      },
    })
  );
  function onSessionStart(anyDeskId: string) {
    document.location = `anydesk:${anyDeskId}`;
    const isNewSession = !extractedAnyDeskIds.find((id) => id === anyDeskId);
    if (isNewSession) {
      appendToNotes(`AnyDesk Sitzung: anydesk:${anyDeskId}`);
    }
    setAnyDeskId("");
    onAbort();
  }
  return (
    <Dialog
      open={open}
      onClose={(e, x) => {
        setAnyDeskId("");
        onAbort();
      }}
      fullScreen={isFullScreen}
      maxWidth="md"
    >
      <DialogTitle style={{ background: theme.palette.primary.main }}>
        AnyDesk Sitzung starten
      </DialogTitle>
      <DialogContent dividers>
        {extractedAnyDeskIds.length > 0 && (
          <>
            <List
              sx={{
                bgcolor: "background.paper",
              }}
              subheader={
                <ListSubheader>Gefundene AnyDesk Sitzungen</ListSubheader>
              }
            >
              {extractedAnyDeskIds.map((anyDeskId) => (
                <ListItem key={anyDeskId} disableGutters>
                  <ListItemButton onClick={() => setAnyDeskId(anyDeskId)}>
                    <ListItemText primary={anyDeskId} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ mt: 2, mb: 1 }} />
          </>
        )}
        <TextField
          label="AnyDesk Sitzungs-ID"
          variant="standard"
          value={anyDeskId}
          onChange={(e) => setAnyDeskId(e.target.value.replaceAll(" ", ""))}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onAbort()} color="secondary">
          Abbrechen
        </Button>
        <Button disabled={!anyDeskId} onClick={() => onSessionStart(anyDeskId)}>
          Sitzung starten
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StartAnyDeskSessionDialog;
