import CryptoJS from "crypto-js";

const SECRET_KEY = "fW'.X~cAE|>G4jG0vW7{+^T6@gODRx";

export function encrypt(input: string) {
  const bytes = CryptoJS.AES.encrypt(input, SECRET_KEY);
  return bytes.toString();
}

export function decrypt(input: string) {
  const bytes = CryptoJS.AES.decrypt(input, SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
