import React from "react";
import { Grid, TextField } from "@mui/material";
import { Activity } from "../../../odata/api";

const EndTimeField = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    return (
      <Grid item xs={12} sm={3}>
        <TextField
          variant="standard"
          required
          label="Endzeit"
          type="time"
          value={activity.EndTime || ""}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: { min: activity.StartTime || null },
          }}
          onChange={(x) =>
            setActivity({ ...activity, EndTime: x.target.value })
          }
          fullWidth
        />
      </Grid>
    );
  }
);

export default EndTimeField;
