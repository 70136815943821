import React from "react";
import SignaturePad from "react-signature-pad-wrapper";

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { Activity, DocumentLine, Order, Priority } from "../../odata/api";
import { calculateQuantity } from "../../odata/utils";
import ElapsedTimeSummary from "../activity/fields/ElapsedTimeSummary";
import DefinitionList from "../misc/DefinitionList";

type ServiceReportDialogProps = {
  open: boolean;
  onConfirm: (data: { name: string; email: string; signature: string }) => void;
  onAbort: () => void;
  activity: Activity;
  order?: Order;
};

const ServiceReportDialog: React.VFC<ServiceReportDialogProps> = ({
  open,
  onConfirm,
  onAbort,
  activity,
  order,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [name, setName] = React.useState<string | undefined>(undefined);
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [signature, setSignature] = React.useState<string | undefined>(
    undefined
  );

  const [signaturePad, setSignaturePad] = React.useState<SignaturePad>();
  const signaturePadRef = React.useCallback((signaturePad: SignaturePad) => {
    setSignaturePad(signaturePad);
  }, []);
  React.useEffect(() => {
    if (signaturePad) {
      signaturePad.instance.addEventListener("endStroke", () => {
        setSignature(signaturePad.instance.toDataURL());
      });
    }
  }, [signaturePad]);

  function clearData() {
    setName("");
    setEmail("");
    signaturePad?.instance.clear();
    setSignature(undefined);
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        // Do not close when clicking into the area outside the dialog.
        if (reason === "backdropClick") return;
        onAbort();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isFullScreen}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        Servicebericht generieren
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent style={{ paddingBottom: 0 }}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Zusammenfassung
                </Typography>
                <Typography variant="h5" component="div">
                  {activity.Details}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {activity.Notes}
                </Typography>
                <DefinitionList
                  details={[
                    {
                      label: "Kundenauftrag NR",
                      data: String(order?.DocEntry),
                    },
                    {
                      label: "Aktivitätstyp",
                      data: activity.ActivityType2?.Name,
                    },
                    {
                      label: "Priorität",
                      data: getPriorityText(activity.Priority),
                    },
                    {
                      label: "Datum",
                      data: activity.StartDate
                        ? new Date(activity.StartDate).toLocaleDateString("de")
                        : null,
                    },
                    {
                      label: "Kunde",
                      data: activity.BusinessPartner?.CardName,
                    },
                    {
                      label: "Mitarbeiter",
                      data: activity.User?.UserName,
                    },
                    {
                      label: "Artikel",
                      data: getArticleInfo(order?.DocumentLines),
                    },
                    {
                      label: "Zu verrechnende Stunden",
                      data: calculateQuantity(activity).toLocaleString("de"),
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
          <ElapsedTimeSummary activity={activity!} />
          <Grid item xs={12}>
            <Typography variant="subtitle1">Unterschrift Kunde</Typography>
            <SignaturePad
              ref={signaturePadRef}
              height={200}
              options={{
                backgroundColor: blue[50],
              }}
              redrawOnResize
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="Name"
              helperText="Der Name des Unterzeichners."
              name="name"
              fullWidth
              required
              error={name === ""}
              value={name || ""}
              onChange={(x) => setName(x.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="standard"
              label="E-Mail"
              type="email"
              inputMode="email"
              name="email"
              prefix="mail"
              helperText="An diese E-Mail Adresse wird der Servicebericht verschickt."
              fullWidth
              value={email || ""}
              onChange={(x) => setEmail(x.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={clearData}>
          Zurücksetzen
        </Button>
        <Button onClick={onAbort} color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={() => {
            onConfirm({ name: name!, email: email!, signature: signature! });
          }}
          color="primary"
          autoFocus
          disabled={!name || signature === undefined}
        >
          Generieren
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceReportDialog;

function getPriorityText(priority: Priority): React.ReactNode {
  switch (priority) {
    case "pr_Low":
      return "Niedrig";
    case "pr_Normal":
      return "Normal";
    case "pr_High":
      return "Hoch";
    default:
      return null;
  }
}

function getArticleInfo(documentLines?: DocumentLine[]): React.ReactNode {
  if (!documentLines) return null;
  const articles = documentLines.length;
  const articleQuantity = documentLines.reduce(
    (count, dl) => count + dl.Quantity,
    0
  );
  return `${articleQuantity} (${articles} Artikeltypen)`;
}
