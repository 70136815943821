import React from "react";
import { useCookie } from "react-use";

import { Button } from "@mui/material";

import { LoginInfo } from "../../odata/api";

export type LoginSectionProps = {
  login: LoginInfo | undefined;
  setLogin: (loginInfo?: LoginInfo) => void;
};

const LoginSection = React.memo(({ login, setLogin }: LoginSectionProps) => {
  const [, , deleteb1SessionCookie] = useCookie("B1SESSION");
  const [, , deleteRouteIdCookie] = useCookie("ROUTEID");
  return (
    <Button
      color="inherit"
      title={login && "Logout"}
      onClick={() => {
        setLogin(null as any as undefined);
        deleteb1SessionCookie();
        deleteRouteIdCookie();
      }}
    >
      {login ? login.userInfo?.fullName || login.username : "Login"}
    </Button>
  );
});

export default LoginSection;
