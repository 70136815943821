import React from "react";
import { Grid, TextField } from "@mui/material";
import { Activity } from "../../../odata/api";

const NotesField = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    return (
      <Grid item xs={12} sm={12}>
        <TextField
          variant="standard"
          label="Anmerkungen"
          value={activity.Notes || ""}
          onChange={(x) =>
            setActivity({ ...activity, Notes: x.target.value || null })
          }
          rows={8}
          multiline
          fullWidth
        />
      </Grid>
    );
  }
);

export default NotesField;
