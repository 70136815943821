import { o, OdataQuery, OHandler } from "odata";

import * as Sentry from "@sentry/browser";

import { API_URL } from "../constants";
import { decrypt } from "../crypto";

const headers = {
  // "X-Proxy-URL": "https://62.94.138.60:50000/b1s/v2",
};

export type LoginInfo = {
  username: string;
  password: string;
  database: string;
  sessionId?: string;
  sessionTimeout?: Date;
  userInfo?: {
    fullName: string;
    internalKey: number;
    mobilePhoneNumber: string;
    eMail: string;
    faxNumber: string | null;
  };
  version?: number;
};

async function authenticate(
  login: LoginInfo
): Promise<{ sessionId: string; sessionTimeout: Date }> {
  const response = await fetch(`${API_URL}/Login`, {
    method: "post",
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    headers,
    body: JSON.stringify({
      CompanyDB: login.database,
      UserName: login.username,
      Password: decrypt(login.password),
    }),
  });
  if (!response.ok) {
    Sentry.withScope((scope) => {
      scope.setExtra("login", { ...login, password: undefined });
      throw new Error("Could not authenticate.");
    });
  }
  const json = await response.json();
  const sessionId = json.SessionId;
  const sessionTimeout = json.SessionTimeout;
  const date = new Date();
  date.setHours(date.getHours() + sessionTimeout);
  return { sessionId, sessionTimeout: date };
}

export async function login(
  login: LoginInfo,
  ignoreTimeout = false
): Promise<LoginInfo> {
  if (
    !ignoreTimeout &&
    login.sessionId &&
    login.sessionTimeout &&
    new Date(login.sessionTimeout) >= new Date()
  ) {
    return login;
  }
  const sessionData = await authenticate(login);
  return { ...login, ...sessionData };
}

function createOHandler(): OHandler {
  return o(API_URL, {
    credentials: "include",
    cache: "no-cache",
    headers: {
      "B1S-ReplaceCollectionsOnPatch": "true",
    },
  });
}

function remapUsername(username: string) {
  if (username.toLocaleLowerCase() == "gunter") {
    return "günter";
  }
  return username;
}

export async function getUserInfo(login: LoginInfo): Promise<LoginInfo> {
  const remappedUsername = remapUsername(login.username);
  const items = await createOHandler()
    .get("v2/Users")
    .query({
      $filter: `UserCode eq '${remappedUsername}'`,
      $select:
        "InternalKey, UserCode, UserName, eMail, MobilePhoneNumber, FaxNumber",
    });
  if (items.length === 0) return login;
  const item = items[0];
  return {
    ...login,
    userInfo: {
      fullName: item.UserName,
      internalKey: item.InternalKey,
      eMail: item.eMail,
      mobilePhoneNumber: item.MobilePhoneNumber,
      faxNumber: item.FaxNumber,
    },
  };
}

function canonicalizeOdataQuery(query: OdataQuery): OdataQuery {
  if (!query.$filter) {
    delete query.$filter;
  }
  if (!query.$orderby) {
    delete query.$orderby;
  }
  return query;
}

async function getElements<T>(
  resource: string,
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery,
  attempt = 1
): Promise<[number, T[]]> {
  const skip = pageSize * page;
  try {
    const response = (await createOHandler()
      .get(`v2/${resource}`)
      .fetch({
        ...canonicalizeOdataQuery(query),
        $skip: skip,
        $top: pageSize,
        $count: true,
      })) as Response;
    if (response.status === 401) {
      console.debug(
        "Got 401, reattempting login before trying to get items again."
      );
      if (attempt > 3) {
        // Give up
        throw Error("Loop while trying to get items.");
      }
      await authenticate(login);
      return getElements(resource, login, page, pageSize, query, attempt + 1);
    }
    const json = await response.json();
    const totalCount = parseInt(json["@odata.count"] || json["odata.count"]);
    return [totalCount, json.value as T[]];
  } catch (ex) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        login: { ...login, password: undefined },
        resource: resource,
        page: page,
        pageSize: pageSize,
        query: query,
      });
      throw Error("Could not retrieve items.");
    });
    return [0, []];
  }
}

export function getActivities(
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery
): Promise<[number, Activity[]]> {
  return getElements("Activities", login, page < 0 ? 0 : page, pageSize, {
    $orderby: "ActivityDate desc, ActivityTime desc",
    ...query,
    $expand: ["BusinessPartner", "ActivityType", "User"].join(", "),
    $select: [
      "*",
      "User/UserName",
      "BusinessPartner/CardName",
      "ActivityType2/Name",
    ].join(", "),
  });
}

export function getActivityTypes(login: LoginInfo) {
  return getElements<ActivityType2>("ActivityTypes", login, 0, 20, {
    $orderby: "Code",
  });
}

async function getItem<T>(
  resource: string,
  id: number,
  login: LoginInfo,
  query?: OdataQuery,
  attempt = 1
): Promise<T | undefined> {
  try {
    const response = (await createOHandler()
      .get(`v2/${resource}(${id})`)
      .fetch(query)) as Response;
    if (response.status === 401) {
      console.debug(
        "Got 401, reattempting login before trying to get item again."
      );
      if (attempt > 3) {
        // Give up
        throw Error("Loop while trying to get the item.");
      }
      await authenticate(login);
      return getItem(resource, id, login, query, attempt + 1);
    }
    const json = await response.json();
    return json as T;
  } catch (ex) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        login: { ...login, password: undefined },
        resource,
        id,
        query,
      });
      throw Error("Could not retrieve item.");
    });
  }
}

async function patchItem<T>(
  resource: string,
  id: number,
  login: LoginInfo,
  item: Partial<T>,
  attempt = 1
): Promise<Partial<T>> {
  const response = (await createOHandler()
    .patch(`v2/${resource}(${id})`, item)
    .fetch()) as Response;
  if (response.status === 401) {
    console.debug(
      "Got 401, reattempting login before trying to get item again."
    );
    if (attempt > 3) {
      // Give up
      throw Error("Loop while trying to get the item.");
    }
    await authenticate(login);
    return patchItem<T>(resource, id, login, item, attempt + 1);
  } else if (response.status < 200 || response.status >= 300) {
    const text = await response.text();
    Sentry.withScope((scope) => {
      scope.setExtras({
        login: { ...login, password: undefined },
        resource: resource,
        id: id,
        item: item,
      });
      throw Error(text);
    });
  }
  return item;
}

async function postItem<T>(
  resource: string,
  login: LoginInfo,
  item: Partial<T>,
  attempt = 1
): Promise<T> {
  const response = (await createOHandler()
    .post(`v2/${resource}`, item)
    .fetch()) as Response;
  if (response.status === 401) {
    console.debug(
      "Got 401, reattempting login before trying to get item again."
    );
    if (attempt > 3) {
      // Give up
      throw Error("Loop while trying to get the item.");
    }
    console.error("o");
    await authenticate(login);
    return postItem<T>(resource, login, item, attempt + 1);
  } else if (response.status < 200 || response.status >= 300) {
    const text = await response.text();
    Sentry.withScope((scope) => {
      scope.setExtras({
        login: { ...login, password: undefined },
        resource: resource,
        item: item,
      });
      throw Error(text);
    });
  }
  return (await response.json()) as T;
}

async function saveItem<T>(
  resource: string,
  id: number | undefined,
  login: LoginInfo,
  item: Partial<T>,
  attempt = 1
): Promise<Partial<T>> {
  if (id === undefined) {
    return await postItem<T>(resource, login, item);
  } else {
    return await patchItem<T>(resource, id, login, item);
  }
}

export type ActivityType =
  | "cn_Task"
  | "cn_Campaign"
  | "cn_Meeting"
  | "cn_Note"
  | "cn_Conversation"
  | "cn_Other";

export type ActivityType2 = {
  Code: number;
  Name: string;
};

export type Priority = "pr_Low" | "pr_Normal" | "pr_High";

export type SAPBoolean = "tNO" | "tYES";

export type Activity = {
  ActivityCode: number | null;
  Activity: ActivityType;
  ActivityType: number;
  ActivityType2?: ActivityType2;
  ActivityDate: string;
  Priority: Priority;
  Closed: SAPBoolean;
  CardCode: string | null;
  BusinessPartner: Pick<
    BusinessPartner,
    "CardCode" | "CardName" | "CardType" | "Valid"
  > | null;
  HandledBy: number | null;
  User: Pick<User, "UserName" | "UserCode" | "FaxNumber" | "eMail"> | null;
  Phone: string | null;
  Fax: string | null;
  StartDate: string | null;
  StartTime: string | null;
  EndTime: string | null;
  Duration: number | null;
  DurationType: "du_Seconds" | null;
  Details: string | null;
  Notes: string | null;
  DocType: string | null;
  DocNum: string | null;
  DocEntry: string | null;
  PreviousActivity: number | null;
  Reminder: SAPBoolean;
};

export async function getActivity(
  login: LoginInfo,
  activityId: number
): Promise<Activity | undefined> {
  var data = await getItem<Activity>("Activities", activityId, login, {
    $expand: "BusinessPartner, ActivityType, User",
    $select:
      "*, User/UserName, User/eMail, BusinessPartner/CardCode, BusinessPartner/CardName, BusinessPartner/CardType, BusinessPartner/Valid, ActivityType2/Name, User/FaxNumber",
  });
  if (!data) return undefined;
  return {
    ActivityCode: data.ActivityCode,
    Activity: data.Activity,
    ActivityType2: data.ActivityType2,
    ActivityType: data.ActivityType,
    ActivityDate: data.ActivityDate,
    Closed: data.Closed,
    Priority: data.Priority,
    CardCode: data.CardCode,
    BusinessPartner: data.BusinessPartner,
    HandledBy: data.HandledBy,
    User: data.User,
    Phone: data.Phone,
    Fax: data.Fax,
    StartDate: data.StartDate,
    StartTime: data.StartTime,
    EndTime: data.EndTime,
    Duration: data.Duration,
    DurationType: data.DurationType,
    Details: data.Details,
    Notes: data.Notes,
    DocType: data.DocType,
    DocNum: data.DocNum,
    DocEntry: data.DocEntry,
    PreviousActivity: data.PreviousActivity,
    Reminder: data.Reminder,
  };
}

export function saveActivity(
  login: LoginInfo,
  activityId: number | undefined,
  activity: Partial<Activity>
): Promise<Partial<Activity>> {
  return saveItem("Activities", activityId, login, activity);
}

export type BusinessPartner = {
  CardCode: string;
  CardName: string;
  CardType: "cSupplier" | "cCustomer" | "cLid";
  GroupCode: string;
  Address: string;
  ZipCode: string;
  City: string;
  Country: string;
  EmailAddress: string | null;
  IBAN: string | null;
  LanguageCode: number;
  CreateDate: string;
  Phone1: string | null;
  Valid: SAPBoolean | null;
};

export function getBusinessPartners(
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery
) {
  return getElements<BusinessPartner>(
    "BusinessPartners",
    login,
    page,
    pageSize,
    {
      $orderby: "CardName",
      ...query,
      $select: [
        "CardCode",
        "CardName",
        "CardType",
        "GroupCode",
        "Address",
        "ZipCode",
        "City",
        "Country",
        "EmailAddress",
        "IBAN",
        "LanguageCode",
        "CreateDate",
        "Phone1",
        "Valid",
      ].join(", "),
    }
  );
}

export type Article = {
  ItemCode: string;
  ItemName: string;
  ItemDescription?: string;
  InternalSerialNumber?: string;
};

export async function getArticles(
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery
): Promise<[number, Article[]]> {
  const [totalCount, articles] = await getElements<Article>(
    "view.svc/HWWORK_ItemOnStockB1SLQuery",
    login,
    page,
    pageSize,
    {
      $orderby: "ItemCode",
      ...query,
      $select: [
        "ItemCode",
        "ItemName",
        "QuantityOnStock",
        "ItemDescription",
        "InternalSerialNumber",
      ].join(", "),
    }
  );
  return [totalCount, articles];
}

export async function findBusinessPartnerByPhoneNumber(
  login: LoginInfo,
  phone: string
) {
  const fields = ["Phone1", "Phone2", "Fax", "Cellular"];
  const [totalCount, businessPartners] = await getElements<BusinessPartner>(
    "view.svc/HWPBX_RubricaB1SLQuery",
    login,
    0,
    3,
    {
      $filter: fields.map((field) => `${field} eq '${phone}'`).join(" or "),
    }
  );
  return totalCount > 0 ? businessPartners[0] : undefined;
}

export type User = {
  InternalKey: number;
  UserCode: string;
  UserName: string | null;
  eMail: string | null;
  FaxNumber: string | null;
};

export function getUsers(
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery
) {
  return getElements<User>("Users", login, page, pageSize, {
    $orderby: "UserName",
    ...query,
    $select: ["InternalKey", "UserCode", "UserName", "eMail", "FaxNumber"].join(
      ", "
    ),
  });
}

export type DocumentLine = {
  LineNum: number;
  ItemCode: string;
  ItemDescription?: string;
  Quantity: number;
  RemainingOpenQuantity?: number;
  SerialNumbers: { InternalSerialNumber: string; Quantity: 1.0 }[];
};

export type DocumentSpecialLine = {
  LineNum: number;
  AfterLineNumber: number;
  OrderNumber: number;
  LineType: "dslt_Text";
  LineText: string;
};

export type Order = {
  DocDate: string;
  DocDueDate: string;
  CardCode: string;
  CardName?: string;
  NumAtCard: string | null;
  DocumentLines: DocumentLine[];
  DocumentSpecialLines: DocumentSpecialLine[];
  AttachmentEntry: number | null;
  DocNum: number | null;
  DocEntry: number | null;
};

export async function saveOrder(
  login: LoginInfo,
  orderId: number | undefined,
  order: Partial<Order>
): Promise<Partial<Order>> {
  const orderAfterSave = await saveItem("Orders", orderId, login, {
    ...order,
    DocumentLines: order.DocumentLines?.map((dl) => ({
      LineNum: dl.LineNum,
      ItemCode: dl.ItemCode,
      Quantity: dl.Quantity,
      SerialNumbers: dl.SerialNumbers,
      RemainingOpenQuantity: dl.RemainingOpenQuantity,
    })),
  });
  return {
    ...orderAfterSave,
    DocumentLines:
      orderAfterSave.DocumentLines?.map(
        (dl: DocumentLine) =>
          ({
            ...dl,
            ItemDescription:
              dl.ItemDescription ||
              order.DocumentLines?.filter(
                (dl2) => dl.ItemCode === dl2.ItemCode
              ).map((dl2) => dl2.ItemDescription)[0] ||
              null,
          } as DocumentLine)
      ) || [],
  };
}

export enum ActivityCodeEnum {
  // Fernwartung
  RemoteMaintenance = 2,
  // Vorort
  OnSite = 3,
}

export enum DocType {
  CustomerOrder = 17,
}

export function getOrders(
  login: LoginInfo,
  page: number,
  pageSize: number,
  query: OdataQuery
): Promise<[number, Order[]]> {
  return getElements("Orders", login, page < 0 ? 0 : page, pageSize, {
    $orderby: "CreationDate desc",
    ...query,
    $select: ["*"].join(", "),
  });
}

export async function getOrder(
  login: LoginInfo,
  docEntry: number
): Promise<Order | null> {
  var data = await getItem<Order>("Orders", docEntry, login);
  if (!data) return null;
  return {
    DocDate: data.DocDate,
    DocNum: data.DocNum,
    DocEntry: data.DocEntry,
    DocDueDate: data.DocDueDate,
    CardCode: data.CardCode,
    CardName: data.CardName,
    NumAtCard: data.NumAtCard,
    DocumentLines: data.DocumentLines.map((dl) => ({
      LineNum: dl.LineNum,
      ItemCode: dl.ItemCode,
      ItemDescription: dl.ItemDescription,
      Quantity: dl.Quantity,
      RemainingOpenQuantity: dl.RemainingOpenQuantity,
      SerialNumbers: dl.SerialNumbers.map((sn) => ({
        InternalSerialNumber: sn.InternalSerialNumber,
        Quantity: sn.Quantity,
      })),
    })),
    DocumentSpecialLines: data.DocumentSpecialLines,
    AttachmentEntry: data.AttachmentEntry,
  };
}

export async function uploadAttachment(
  login: LoginInfo,
  blob: Blob,
  filename: string
) {
  const formData = new FormData();
  formData.append("files", blob, filename);
  const response = await fetch(`${API_URL}/Attachments2`, {
    method: "POST",
    body: formData,
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    headers,
  });
  const json = await response.json();
  return {
    id: json.AbsoluteEntry as number,
    override: (json.Attachments2_Lines?.Override || "tNO") === "tYES",
  };
}

export async function getAttachments(login: LoginInfo, attachmentId: number) {
  const response = await getItem<any>("Attachments2", attachmentId, login, {});
  return ((response.Attachments2_Lines || []) as any[]).map((a: any) => ({
    filename: a.FileName as string,
    extension: a.FileExtension as string,
    override: (a.Override || "tNO") === "tYES",
    text: a.FreeText as string | null,
  }));
}

type MailContent = {
  subject: string;
  body: string;
  recipients: { name: string; emailAddress: string }[];
};

export async function sendMail(mailContent: MailContent) {
  const response = await fetch(`${API_URL}/Messages`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    headers,
    body: JSON.stringify({
      Subject: mailContent.subject,
      Text: mailContent.body,
      RecipientCollection: mailContent.recipients.map((r) => ({
        UserType: "rt_RandomUser",
        NameTo: r.name,
        SendEmail: "tYES",
        EmailAddress: r.emailAddress,
      })),
    }),
  });
  const json = await response.json();
  if (json.error) {
    alert("Beim Senden der Nachricht ist ein Fehler aufgetreten.");
  }
}

export type DeliverableOrder = {
  DocEntry: number;
  DocNum: number;
  DocDate: string;
  CardCode: string;
  CardName: string;
};

export function getDeliverableOrders(
  login: LoginInfo
): Promise<[number, DeliverableOrder[]]> {
  return getElements("Orders", login, 0, 1000, {
    $orderby: "CardName, DocDate",
    $filter: "U_Lieferbar eq 'Y' and DocumentStatus eq 'bost_Open'",
    $select: ["DocEntry", "DocNum", "DocDate", "CardCode", "CardName"].join(
      ", "
    ),
  });
}
