import React, { Suspense, useEffect } from "react";

import { Box, Typography } from "@mui/material";

import ErrorBoundary from "../misc/ErrorBoundary";

type PageProps = {
  title?: string;
  children?: React.ReactNode;
};

export function LoadInformation() {
  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "200px",
      }}
    >
      <Typography variant="body1">Lade Daten...</Typography>
    </Box>
  );
}

const Page: React.FC<PageProps> = React.memo((props) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  return (
    <Suspense fallback={<LoadInformation />}>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Suspense>
  );
});

export default Page as React.FunctionComponent<PageProps>;
