import React, { useMemo } from "react";

import { Grid } from "@mui/material";
import { Alert } from "@mui/material";

import { Activity } from "../../../odata/api";
import {
  calculateElapsedTime,
  calculateQuantity,
  humanizeDuration,
} from "../../../odata/utils";

const ElapsedTimeSummary: React.VFC<{ activity: Activity }> = React.memo(
  ({ activity }) => {
    const [elapsed, humanizedElapsed, quantity] = useMemo(() => {
      if (!activity) {
        return [0, undefined, undefined];
      }
      const elapsed = calculateElapsedTime(activity);
      const humanized = humanizeDuration(elapsed * 60 * 1000);
      const quantity = calculateQuantity(activity);
      return [elapsed, humanized, quantity];
    }, [activity]);
    return (
      <Grid item xs={12} sm={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Alert severity="info">Verstrichene Zeit: {humanizedElapsed}</Alert>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Alert severity={elapsed >= 0 ? "success" : "error"}>
              {activity.Closed === "tYES"
                ? "Verrechnete Stunden"
                : "Zu verrechnende Stunden"}
              {": "}
              {quantity !== undefined ? quantity.toLocaleString("de") : null}
            </Alert>
          </Grid>
        </Grid>
      </Grid>
    );
  },
  (prev, next) => {
    const pactivity = prev.activity;
    const nactivity = next.activity;
    return (
      pactivity.Duration === nactivity.Duration &&
      pactivity.StartDate === nactivity.StartDate &&
      pactivity.StartTime === nactivity.StartTime &&
      pactivity.EndTime === nactivity.EndTime
    );
  }
);

export default ElapsedTimeSummary;
