import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallow } from "zustand/shallow";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogContentText,
  Grid,
  LinearProgress,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";

import { useQuery } from "../../hooks";
import { useAsync } from "react-use";
import { Activity, ActivityCodeEnum, LoginInfo } from "../../odata/api";
import { isOnSiteActivity as isOnSiteActivityF } from "../../odata/utils";
import AlertDialog from "../dialogs/AlertDialog";
import ElapsedTimeSummary from "./fields/ElapsedTimeSummary";
import OnSiteFields from "./fields/OnSiteFields";
import { useActivityStore } from "./state";
import { useNavigate } from "react-router";
import BusinessPartnerElement from "./fields/BusinessPartnerField";
import UserElement from "./fields/UserField";
import ActivityTypeField from "./fields/ActivityTypeField";
import ActivityType2Field from "./fields/ActivityType2Field";
import PriorityField from "./fields/PriorityField";
import PhoneField from "./fields/PhoneField";
import FaxField from "./fields/FaxField";
import StartDateField from "./fields/StartDateField";
import StartTimeField from "./fields/StartTimeField";
import EndDateField from "./fields/EndDateField";
import EndTimeField from "./fields/EndTimeField";
import DetailsField from "./fields/DetailsField";
import NotesField from "./fields/NotesField";
import PreviousActivity from "./fields/PreviousActivity";
import ReminderField from "./fields/ReminderField";

const RenderHeader = React.memo(
  ({ activity, dirty }: { activity: Activity; dirty: boolean }) => {
    return (
      <>
        {activity.ActivityCode ? activity.ActivityCode : "[Neu]"}
        {" - "}
        {activity.Details} {" - "}
        {activity.StartDate &&
          new Date(activity.StartDate).toLocaleDateString("de")}
        {activity.Closed === "tYES" && " (ABGESCHLOSSEN)"}
        {dirty && " *"}
      </>
    );
  },
  (prev, next) =>
    prev.dirty === next.dirty &&
    prev.activity.Details === next.activity.Details &&
    prev.activity.ActivityCode === next.activity.ActivityCode &&
    prev.activity.StartDate === next.activity.StartDate &&
    prev.activity.Closed === next.activity.Closed
);

type ActivityFormProps = {
  activityId?: number;
  login: LoginInfo;
};

function getValidationMessage(activity: Activity): string | undefined {
  if (
    activity.BusinessPartner &&
    activity.BusinessPartner?.CardType === "cSupplier" &&
    activity.ActivityType === ActivityCodeEnum.RemoteMaintenance
  ) {
    return "Eine Fernwartung darf nur für einen Kunden erstellt werden.";
  } else if (
    activity.BusinessPartner &&
    activity.BusinessPartner?.CardType === "cSupplier" &&
    activity.ActivityType === ActivityCodeEnum.OnSite
  ) {
    return "Eine Vorort Aktivität darf nur für einen Kunden erstellt werden.";
  } else if (
    activity.BusinessPartner &&
    activity.BusinessPartner?.Valid !== "tYES" &&
    activity.ActivityType === ActivityCodeEnum.RemoteMaintenance
  ) {
    return "Eine Fernwartung darf nicht für einen inaktiven Kunden erstellt werden.";
  } else if (
    activity.User &&
    !activity.User?.FaxNumber &&
    activity.ActivityType === ActivityCodeEnum.RemoteMaintenance
  ) {
    return `Der Benutzer '${activity.User?.UserName}' besitzt keine Fax-Nummer. Diese wird benötigt um einen Kundenauftrag zu erstellen.`;
  } else if (
    activity.StartTime &&
    activity.EndTime &&
    activity.EndTime < activity.StartTime
  ) {
    return `Die Endzeit der Aktivität darf nicht vor der Startzeit liegen.`;
  }
}

const ActivityForm = React.memo(({ activityId, login }: ActivityFormProps) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const navigate = useNavigate();
  const query = useQuery();
  const phone = query.get("phone");
  const previousActivityId = query.get("previousActivity");

  const {
    activity,
    load,
    loadPreviousActivity,
    setActivity,
    setPreviousActivity,
    unsetPreviousActivity,
    previousActivity,
    dirty,
    busy,
    save,
    reset,
    closeOnSiteActivity,
  } = useActivityStore(
    (state) => ({
      activity: state.activity,
      load: state.load,
      loadPreviousActivity: state.loadPreviousActivity,
      setActivity: state.setActivity,
      setPreviousActivity: state.setPreviousActivity,
      unsetPreviousActivity: state.unsetPreviousActivity,
      previousActivity: state.previousActivity,
      dirty: state.dirty,
      busy: state.busy,
      save: state.save,
      reset: state.reset,
      closeOnSiteActivity: state.closeOnSiteActivity,
    }),
    shallow
  );

  useAsync(async () => {
    if (activity && previousActivityId) {
      setPreviousActivity(parseInt(previousActivityId));
    }
    await loadPreviousActivity();
    return () => {
      unsetPreviousActivity();
    };
  }, [
    previousActivityId,
    setPreviousActivity,
    unsetPreviousActivity,
    activity?.CardCode,
    activity?.PreviousActivity,
  ]);

  useEffect(() => {
    load(activityId, phone || undefined);
    return () => {
      reset();
    };
  }, [activityId, login, load, reset, phone]);

  const invalidActivityMessage = useMemo(
    () => (activity ? getValidationMessage(activity) : undefined),
    [activity]
  );

  const [openCloseActivityDialog, setOpenCloseActivityDialog] = useState(false);
  const [openCloseServiceReport, setOpenCloseServiceReport] = useState(false);
  const [openFollowUpActivityDialog, setOpenFollowUpActivityDialog] =
    useState(false);
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] =
    useState(false);

  const handleConfirmCloseActivity = useCallback(
    async (result: "ok" | "cancel") => {
      setOpenCloseActivityDialog(false);
      if (result === "ok") {
        await save(true);
        setOpenFollowUpActivityDialog(true);
      }
    },
    [setOpenCloseActivityDialog, setOpenFollowUpActivityDialog, save]
  );

  const handleConfirmFollowUpActivity = useCallback(
    async (result: "ok" | "cancel") => {
      setOpenFollowUpActivityDialog(false);
      if (result === "ok") {
        window.open(
          `/new?phone=${activity?.Phone}&previousActivity=${activity?.ActivityCode}`
        );
      } else {
        navigate("/");
      }
    },
    [setOpenFollowUpActivityDialog, navigate, activity?.Phone]
  );

  const handleConfirmUnsavedChanges = useCallback(
    (result: "ok" | "cancel") => {
      setOpenUnsavedChangesDialog(false);
      if (result === "ok") navigate("/");
    },
    [setOpenUnsavedChangesDialog, navigate]
  );

  const isOnSiteActivity = useMemo(
    () => isOnSiteActivityF(login, activity),
    [activity, login]
  );

  const handleBack = useCallback(() => {
    if (dirty) {
      setOpenUnsavedChangesDialog(true);
    } else {
      navigate("/");
    }
  }, [dirty, navigate]);

  const handleCloseActivity = useCallback(async () => {
    if (activity?.ActivityType === ActivityCodeEnum.OnSite) {
      // Save first, then open the service report dialog.
      await save();

      setOpenCloseServiceReport(true);
    } else {
      setOpenCloseActivityDialog(true);
    }
  }, [activity?.ActivityType, setOpenCloseActivityDialog, save]);

  const handleSaveActivity = useCallback(async () => {
    const activityCode = await save();

    import.meta.env.PROD
      ? navigate("/")
      : activityCode && navigate(`/${activityCode}`, { replace: true });
  }, [navigate, save]);

  const handleConfirmServiceDialogCreation = useCallback(
    async (data: { name: string; email: string; signature: string }) => {
      setOpenCloseServiceReport(false);

      await closeOnSiteActivity(data);

      setOpenFollowUpActivityDialog(true);
    },
    [closeOnSiteActivity]
  );

  const canSave = useMemo(
    () =>
      activity
        ? !!activity.CardCode &&
          !!activity.Details &&
          activity.ActivityType !== -100
        : false,
    [activity]
  );

  return (
    <>
      <Card variant="elevation" raised elevation={isDesktop ? 6 : 0}>
        <CardHeader
          title={
            activity ? (
              <RenderHeader activity={activity} dirty={dirty} />
            ) : (
              "Lade..."
            )
          }
        />
        <CardContent>
          {false && !import.meta.env.PROD && (
            <Grid>
              <pre>{JSON.stringify(activity, null, "  ")}</pre>
            </Grid>
          )}
          {activity && (
            <Grid container spacing={3}>
              <PreviousActivity
                previousActivity={previousActivity || undefined}
              />
              <ActivityTypeField
                activity={activity}
                setActivity={setActivity}
              />
              <ActivityType2Field
                activity={activity}
                setActivity={setActivity}
              />
              <PriorityField activity={activity} setActivity={setActivity} />
              <BusinessPartnerElement />
              <UserElement />
              <PhoneField activity={activity} setActivity={setActivity} />
              <FaxField activity={activity} setActivity={setActivity} />
              <StartDateField activity={activity} setActivity={setActivity} />
              <StartTimeField activity={activity} setActivity={setActivity} />
              <EndDateField activity={activity} />
              <EndTimeField activity={activity} setActivity={setActivity} />
              <ElapsedTimeSummary activity={activity} />
              <DetailsField activity={activity} setActivity={setActivity} />
              <NotesField activity={activity} setActivity={setActivity} />
              <ReminderField activity={activity} setActivity={setActivity} />
              {isOnSiteActivity && (
                <OnSiteFields
                  login={login}
                  serviceReportDialogOpen={openCloseServiceReport}
                  confirmServiceDialogCreation={
                    handleConfirmServiceDialogCreation
                  }
                  cancelServiceDialogCreation={() =>
                    setOpenCloseServiceReport(false)
                  }
                />
              )}
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            onClick={handleSaveActivity}
            disabled={busy || !canSave || !!invalidActivityMessage}
          >
            Speichern
          </Button>
          <Button
            color="secondary"
            onClick={handleCloseActivity}
            disabled={
              busy ||
              !canSave ||
              !!invalidActivityMessage ||
              activity?.Closed === "tYES"
            }
          >
            Abschließen
          </Button>
          <Button onClick={handleBack}>Zurück</Button>
        </CardActions>
        {!!invalidActivityMessage && (
          <Alert severity="error" sx={{ mx: 2, mb: 2 }}>
            <AlertTitle>Achtung</AlertTitle>
            {invalidActivityMessage}
          </Alert>
        )}
        {busy && <LinearProgress />}
      </Card>
      <AlertDialog
        title="Aktivität abschließen"
        open={openCloseActivityDialog}
        onClose={handleConfirmCloseActivity}
        confirmText="Abschließen"
        cancelText="Abbrechen"
      >
        <DialogContentText id="alert-dialog-description">
          Willst du die Aktivität wirklich abschließen?
        </DialogContentText>
        <ElapsedTimeSummary activity={activity!} />
      </AlertDialog>
      <AlertDialog
        title="Folgeaktivität erstellen"
        open={openFollowUpActivityDialog}
        onClose={handleConfirmFollowUpActivity}
        confirmText="Erstellen"
        cancelText="Abbrechen"
      >
        <DialogContentText id="alert-dialog-description">
          Willst du eine Folgeaktivität erstellen?
        </DialogContentText>
      </AlertDialog>
      <AlertDialog
        title="Ungespeicherte Änderungen"
        open={openUnsavedChangesDialog}
        onClose={handleConfirmUnsavedChanges}
        confirmText="Verwerfen"
        cancelText="Abbrechen"
      >
        <DialogContentText id="alert-dialog-description">
          Bist du dir sicher, dass du diese Änderungen verwerfen möchtest?
        </DialogContentText>
      </AlertDialog>
    </>
  );
});

export default ActivityForm;
