import { useAtom } from "jotai";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box, Container } from "@mui/material";
import * as Sentry from "@sentry/browser";
import AppBar from "./components/AppBar";
import LoginForm from "./components/login/LoginForm";
import { useLoginManagement } from "./hooks";
import { loginState } from "./state";
import { EditActivityPage } from "./components/pages/EditActivityPage";
import { OrdersPage } from "./components/pages/OrdersPage";
import { NewActivityPage } from "./components/pages/NewActivityPage";
import { ActivitiesPage } from "./components/pages/ActivitiesPage";
import { ViewOrderPage } from "./components/pages/ViewOrderPage";

export const Content: React.VFC = () => {
  const [login, setLogin] = useAtom(loginState);
  const path = useLocation();

  useLoginManagement();

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: login?.userInfo?.internalKey
        ? String(login.userInfo.internalKey)
        : undefined,
      username: login?.username,
      email: login?.userInfo?.eMail,
    });
  });

  const isRefreshActive = path.pathname === "/";
  const [refresh, setRefresh] = React.useState(0);

  function handleRefresh() {
    setRefresh((refresh) => {
      return refresh + 1;
    });
  }

  return (
    <Box
      sx={{
        height: 1,
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <AppBar
        login={login}
        setLogin={setLogin}
        onRefreshClicked={handleRefresh}
        isRefreshActive={isRefreshActive}
      />
      <Container sx={{ padding: { xs: 1, sm: 3 } }}>
        {login ? (
          <Routes>
            <Route
              path="/"
              element={
                <ActivitiesPage
                  login={login}
                  setLogin={setLogin}
                  refresh={refresh}
                  handleRefresh={handleRefresh}
                />
              }
            />
            <Route
              path="/:activityId"
              element={<EditActivityPage login={login} />}
            />
            <Route path="/new" element={<NewActivityPage login={login} />} />
            <Route path="/orders" element={<OrdersPage login={login} />} />
            <Route
              path="/orders/:orderId"
              element={<ViewOrderPage login={login} />}
            />
          </Routes>
        ) : (
          <LoginForm setLogin={setLogin} />
        )}
      </Container>
    </Box>
  );
};
