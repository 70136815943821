import React from "react";
import { Grid, Typography } from "@mui/material";
import { getAttachments, LoginInfo, Order } from "../../odata/api";
import DefinitionList from "../misc/DefinitionList";
import { useAsync } from "react-use";

const ServiceReportInfo = ({
  login,
  order,
}: {
  login: LoginInfo;
  order: Order;
}) => {
  const [attachments, setAttachments] = React.useState<
    Awaited<ReturnType<typeof getAttachments>>
  >([]);
  useAsync(async () => {
    if (order.AttachmentEntry) {
      setAttachments(await getAttachments(login, order.AttachmentEntry));
    }
  }, [order.AttachmentEntry]);
  const data: any = order.DocumentSpecialLines.reduce((obj, line) => {
    const chunks = line.LineText.split(":");
    return {
      ...obj,
      [chunks[0]]: chunks[1].trim(),
    };
  }, {} as Record<string, any>);
  return (
    <Grid item>
      <Typography variant="h6">Signaturdaten</Typography>
      <DefinitionList
        details={[
          { label: "Name", data: data["SIGNATURE_NAME"] },
          { label: "E-Mail", data: data["SIGNATURE_EMAIL"] },
          {
            label: "Digital unterschrieben",
            data: order.AttachmentEntry !== null ? "Ja" : "Nein",
          },
          { label: "Attachment ID", data: order.AttachmentEntry },
          ...attachments.map((a, index) => ({
            label: `File #${index + 1}`,
            data: `${a.filename}.${a.extension}`,
          })),
        ]}
      />
    </Grid>
  );
};

export default ServiceReportInfo;
