import { Suspense, useEffect, useState } from "react";
import { useAsync, useDebounce } from "react-use";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Alert } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";

import { getUsers, LoginInfo, User } from "../../odata/api";
import { LoadInformation } from "../pages/Page";

type UserSelectionDialogProps = {
  login: LoginInfo;
  open: boolean;
  onConfirm: (user: User) => void;
  onAbort: () => void;
};

const UserSelectionDialog: React.VFC<UserSelectionDialogProps> = ({
  login,
  open: openProp,
  onConfirm,
  onAbort,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(openProp);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedInternalKey, setSelectedInternalKey] = useState<
    number | undefined
  >();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [users, setUsers] = useState<User[] | undefined>();

  useDebounce(
    () => {
      setDebouncedSearchText(searchText);
    },
    500,
    [searchText]
  );

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  useAsync(async () => {
    if (open) {
      const [, users] = await getUsers(login, 0, 20, {
        $filter: `(
          (
            contains(UserName, '${debouncedSearchText}')
            or contains(UserCode, '${debouncedSearchText}')
          )
          and Locked eq 'tNO'
        )`,
      });
      setUsers(users);
    } else {
      setUsers(undefined);
      setSelectedInternalKey(undefined);
      setSearchText("");
      setDebouncedSearchText("");
    }
  }, [login, open, debouncedSearchText]);

  function handleConfirm() {
    setOpen(false);
    const users_ =
      users?.filter((u) => u.InternalKey === selectedInternalKey) || [];
    if (users_?.length > 0) {
      onConfirm(users_[0]);
    } else {
      alert("Benutzer nicht gefunden.");
    }
  }
  function handleAbort() {
    setOpen(false);
    onAbort();
  }

  return (
    <Dialog
      open={open}
      onClose={(e, x) => {
        onAbort();
      }}
      fullWidth
      fullScreen={isFullScreen}
      maxWidth="md"
    >
      <DialogTitle style={{ background: theme.palette.primary.main }}>
        Benutzer {selectedInternalKey && ` - ${selectedInternalKey}`}
      </DialogTitle>
      <DialogContent dividers>
        <ErrorBoundary>
          <DialogContentText>Wähle den gewünschten Benutzer:</DialogContentText>
          <Suspense fallback={<LoadInformation />}>
            <TextField
              variant="standard"
              label="Suche"
              autoFocus
              placeholder="Benutzername"
              fullWidth
              value={searchText}
              onChange={(x) => setSearchText(x.target.value)}
            />
            <List>
              {users ? (
                users.map((user) => (
                  <ListItem
                    key={user.InternalKey}
                    button
                    selected={selectedInternalKey === user.InternalKey}
                    onClick={() => setSelectedInternalKey(user.InternalKey)}
                    onDoubleClick={() => {
                      setSelectedInternalKey(user.InternalKey);
                      handleConfirm();
                    }}
                  >
                    <ListItemAvatar>
                      <PersonIcon />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          {user.UserCode}
                          {user.UserName && ` - ${user.UserName}`}
                          {user.FaxNumber && ` [${user.FaxNumber}]`}
                        </>
                      }
                      secondary={`${user.eMail || ""}`}
                    />
                  </ListItem>
                ))
              ) : (
                <LoadInformation />
              )}
            </List>
            {users && users.length >= 20 && (
              <Alert severity="info">
                Anzahl der Benutzer über die Suche eingrenzen...
              </Alert>
            )}
          </Suspense>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAbort()}>Abbrechen</Button>
        <Button onClick={() => handleConfirm()} disabled={!selectedInternalKey}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectionDialog;
