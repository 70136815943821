import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";

const DefinitionList = ({
  details,
}: {
  details: {
    label: React.ReactNode;
    data: React.ReactNode;
    icon?: React.ReactNode;
  }[];
}) => (
  <List dense>
    {details.map((item, index) => (
      <ListItem divider key={index} alignItems="flex-start">
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}

        <ListItemText className="br-break" primary={item.label} />

        <ListItemSecondaryAction>
          <ListItemText className="br-break" primary={item.data} />
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);

export default DefinitionList;
