import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Activity } from "../../../odata/api";
import { activityTypes2State } from "../../activities/state";
import { useAtomValue } from "jotai/react";

const ActivityType2Field = React.memo(
  ({
    activity,
    setActivity,
  }: {
    activity: Activity;
    setActivity: (activity: Activity) => void;
  }) => {
    const activityTypes = useAtomValue(activityTypes2State);
    return (
      <Grid item xs={12} md={4} sm={6}>
        <TextField
          required
          variant="standard"
          label="Typ"
          value={activity.ActivityType === -100 ? "" : activity.ActivityType}
          onChange={(x) => {
            const code = Number(x.target.value);
            const names = activityTypes
              .filter((at) => at.Code === Number(x.target.value))
              .map((at) => at.Name);
            const name = names.length > 0 ? names[0] : "";
            setActivity({
              ...activity,
              ActivityType: code,
              ActivityType2: {
                Code: code,
                Name: name,
              },
            });
          }}
          fullWidth
          select
        >
          {activityTypes.map((activityType) => (
            <MenuItem key={activityType.Code} value={activityType.Code}>
              {activityType.Name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }
);

export default ActivityType2Field;
