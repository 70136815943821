import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import { Activity } from "../../../odata/api";

type ReminderFieldProps = {
  activity: Activity;
  setActivity: (activity: Activity) => void;
};

export default ({ activity, setActivity }: ReminderFieldProps) => {
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            checked={activity.Reminder === "tYES" ? true : false}
            onChange={(_, checked) =>
              setActivity({ ...activity, Reminder: checked ? "tYES" : "tNO" })
            }
          />
        }
        label="Benachrichtigen"
      />
    </Grid>
  );
};
