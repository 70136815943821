import { useMemo, useState } from "react";
import isEqual from "react-fast-compare";
import { useDeepCompareEffect } from "react-use";

import { useMediaQuery, useTheme } from "@mui/material";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";

import {
  Activity,
  getActivities,
  login as loginF,
  LoginInfo,
} from "../../odata/api";
import { getFilterValue, getSortValue } from "../../odata/utils";
import { columnsTemplate } from "./state";
import { SUPPORT_USER_ID } from "../../constants";

export function useFetchActivities(
  login: LoginInfo,
  setLogin: (login: LoginInfo) => void,
  pageNumber: number,
  pageSize: number,
  filterModel: GridFilterModel,
  sortModel: GridSortModel,
  handledBy: number | undefined,
  refresh: number
) {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);

  const [activities, setActivities] = useState<Activity[]>([]);
  const [activitiesTotalCount, setActivitiesTotalCount] = useState(0);

  const columns = useMemo(() => {
    return columnsTemplate.map((column) => {
      if (column.field === "Details")
        return { ...column, flex: isSmallWidth ? undefined : 1 };
      else return column;
    });
  }, [isSmallWidth]);

  useDeepCompareEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);

      const newLogin = await loginF(login);
      if (!isEqual(newLogin, login)) {
        setLogin(newLogin);
      }

      let $filter = getFilterValue(columns, filterModel);
      if (handledBy) {
        $filter += ` and (HandledBy eq ${handledBy} or HandledBy eq ${SUPPORT_USER_ID})`;
      }
      const $orderby = getSortValue(columns, sortModel);

      const [totalCount, activities] = await getActivities(
        login,
        pageNumber,
        pageSize,
        {
          $filter,
          $orderby,
        }
      );

      if (!active) return;

      const activitiesWithId = activities.map((a: any) => ({
        ...a,
        id: a.ActivityCode,
      }));

      setActivitiesTotalCount(totalCount);
      setActivities(activitiesWithId);
      setLoading(false);
    })();

    return () => {
      setActivitiesTotalCount(0);
      setActivities([]);
      setLoading(false);
      active = false;
    };
  }, [pageNumber, pageSize, sortModel, filterModel, handledBy, refresh]);

  return { activities, activitiesTotalCount, loading };
}
