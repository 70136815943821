import { useMemo } from "react";

import { useMediaQuery, createTheme, ThemeOptions } from "@mui/material";
import { orange, red } from "@mui/material/colors";

const defaultTheme: ThemeOptions = {
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: red[900],
    },
    secondary: {
      main: orange[700],
    },
  },
  ...defaultTheme,
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: red[500],
    },
    secondary: {
      main: orange[700],
    },
  },
  ...defaultTheme,
});

export function useTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  return useMemo(
    () => (prefersDarkMode ? darkTheme : lightTheme),
    [prefersDarkMode]
  );
}
