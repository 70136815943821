// import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom/client";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import ErrorBoundary from "./components/misc/ErrorBoundary";
import { createRoutesFromElements } from "react-router-dom";

// const history = createBrowserHistory();

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://d60ad01d79304f59b4fc803cda7bd3a7@o552768.ingest.sentry.io/5802637",
    environment: import.meta.env.MODE,
    integrations: [
      new Integrations.BrowserTracing({
        // FIXME: wait for react route 6 update
        // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById("root") as HTMLDivElement;

ReactDOM.createRoot(container).render(
  <React.StrictMode>
    {import.meta.env.PROD ? (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ) : (
      <App />
    )}
  </React.StrictMode>
);
