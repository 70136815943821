import React, { Suspense, useEffect, useState } from "react";
import { useAsync, useDebounce } from "react-use";
import { shallow } from "zustand/shallow";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArticleWithSerialNumber from "@mui/icons-material/Category";
import NormalArticle from "@mui/icons-material/CategoryOutlined";
import WorkArticle from "@mui/icons-material/PersonOutline";
import { Alert } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";

import { Article, getArticles, LoginInfo } from "../../odata/api";
import { LoadInformation } from "../pages/Page";

type ArticleSelectionDialogProps = {
  login: LoginInfo;
  open: boolean;
  onConfirm: (article: Article, serialNumber?: string) => void;
  onAbort: () => void;
};

const ArticleSelectionDialog: React.VFC<ArticleSelectionDialogProps> = ({
  login,
  open: openProp,
  onConfirm,
  onAbort,
}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(openProp);
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedCardCode, setSelectedCardCode] = useState<
    [string, string | undefined] | undefined
  >();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  useDebounce(
    () => {
      setDebouncedSearchText(searchText);
    },
    500,
    [searchText]
  );
  const [articles, setArticles] = useState<Article[] | undefined>();

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  useAsync(async () => {
    if (open) {
      const [, articles] = await getArticles(login, 0, 20, {
        $filter: `
          ItemName ne '' and (
            contains(ItemName, '${debouncedSearchText}')
            or startswith(ItemCode, '${debouncedSearchText}')
            or substringof(InternalSerialNumber, '${debouncedSearchText}')
            or substringof(ItemDescription, '${debouncedSearchText}')
          )`,
      });
      setArticles(articles);
    } else {
      setArticles(undefined);
      setSelectedCardCode(undefined);
      setSearchText("");
      setDebouncedSearchText("");
    }
  }, [login, open, debouncedSearchText]);

  async function handleConfirm() {
    setOpen(false);
    const businessPartners_ =
      articles?.filter((bp) =>
        shallow([bp.ItemCode, bp.InternalSerialNumber], selectedCardCode)
      ) || [];
    if (businessPartners_?.length > 0) {
      const article = businessPartners_[0];
      const serialNumber = article.InternalSerialNumber;
      onConfirm(article, serialNumber);
    } else {
      alert("Artikel nicht gefunden.");
      onAbort();
    }
  }
  function handleAbort() {
    setOpen(false);
    onAbort();
  }

  return (
    <Dialog
      open={open}
      onClose={(e, x) => {
        onAbort();
      }}
      fullWidth
      fullScreen={isFullScreen}
      maxWidth="md"
    >
      <DialogTitle style={{ background: theme.palette.primary.main }}>
        Artikel {selectedCardCode && ` - ${selectedCardCode[0]}`}{" "}
        {selectedCardCode && selectedCardCode[1] && ` - ${selectedCardCode[1]}`}
      </DialogTitle>
      <DialogContent dividers>
        <ErrorBoundary>
          <DialogContentText>Wähle den gewünschten Artikel:</DialogContentText>
          <Suspense fallback={<LoadInformation />}>
            <TextField
              variant="standard"
              label="Suche"
              autoFocus
              placeholder="Code oder Artikelbezeichnung"
              fullWidth
              value={searchText}
              onChange={(x) => setSearchText(x.target.value)}
            />
            <List>
              {articles ? (
                articles.map((article) => (
                  <ListItem
                    key={`${article.ItemCode}-${article.InternalSerialNumber}`}
                    button
                    selected={shallow(selectedCardCode, [
                      article.ItemCode,
                      article.InternalSerialNumber,
                    ])}
                    onClick={() =>
                      setSelectedCardCode([
                        article.ItemCode,
                        article.InternalSerialNumber,
                      ])
                    }
                    onDoubleClick={() => {
                      setSelectedCardCode([
                        article.ItemCode,
                        article.InternalSerialNumber,
                      ]);
                      handleConfirm();
                    }}
                  >
                    <ListItemAvatar>
                      {article.InternalSerialNumber ? (
                        <ArticleWithSerialNumber />
                      ) : article.ItemCode.startsWith("HWA") ? (
                        <WorkArticle />
                      ) : (
                        <NormalArticle />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        article.InternalSerialNumber
                          ? `${article.ItemCode} [${article.InternalSerialNumber}]`
                          : article.ItemCode
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            // sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            {article.ItemName}
                          </Typography>
                          {article.ItemDescription ? (
                            <span
                              style={{
                                whiteSpace: "pre",
                                display: "block",
                                unicodeBidi: "embed",
                              }}
                            >
                              {article.ItemDescription.replaceAll("\r", "\n")}
                            </span>
                          ) : null}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <LoadInformation />
              )}
            </List>
            {articles && articles.length >= 20 && (
              <Alert severity="info">
                Anzahl der Artikel über die Suche eingrenzen...
              </Alert>
            )}
          </Suspense>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleAbort()}>Abbrechen</Button>
        <Button onClick={() => handleConfirm()} disabled={!selectedCardCode}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleSelectionDialog;
