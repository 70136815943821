import React from "react";
import { Grid, TextField } from "@mui/material";
import { Activity } from "../../../odata/api";
import { convertDateTimeToDate } from "../../../odata/utils";

const EndDateField = React.memo(({ activity }: { activity: Activity }) => {
    const startDateStripped = convertDateTimeToDate(activity.StartDate)
  return (
    <Grid item xs={12} sm={3}>
      <TextField
        variant="standard"
        label="Enddatum"
        type="date"
        value={startDateStripped || ""}
        fullWidth
        inputProps={{
          min: startDateStripped,
          max: startDateStripped,
        }}
      />
    </Grid>
  );
});

export default EndDateField;
