import { useNavigate, useParams } from "react-router-dom";
import { LoginInfo, Order, getOrder } from "../../odata/api";
import { useAsync } from "react-use";
import { useCallback, useState } from "react";
import { Box, Theme } from "@mui/system";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getBackgroundColor } from "../../odata/utils";

const useStyles = makeStyles((theme: any) => {
  console.log(theme);
  return {
    definitionList: {
      display: "grid",
      gridTemplateColumns: "max-content auto",
      "& > dt": { ...theme.typography.subtitle1, gridColumnStart: 1 },
      "& > dd": { ...theme.typography.body1, gridColumnStart: 2 },
    },
    differenceRow: {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode
      ),
    },
  };
});

export const ViewOrderPage: React.FC<{ login: LoginInfo }> = ({ login }) => {
  const classes = useStyles();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const params = useParams();
  const orderId = params.orderId;

  const [order, setOrder] = useState<Order | undefined | null>(undefined);

  useAsync(async () => {
    const order = orderId && (await getOrder(login, parseInt(orderId)));
    order && setOrder(order);
    return () => {
      setOrder(undefined);
    };
  }, [orderId]);

  const handleBack = useCallback(() => {
    navigate("/orders");
  }, [navigate]);

  if (order === undefined) return <Box>Lade...</Box>;
  else if (order === null)
    return <Box>Kein Auftrag mit der ID {orderId} gefunden.</Box>;
  return (
    <Card variant="elevation" raised elevation={isDesktop ? 6 : 0}>
      <CardHeader title={`${order.DocNum} - ${order.CardName}`} />
      <CardContent>
        <dl className={classes.definitionList}>
          <dt>DocNum:</dt>
          <dd>{order.DocNum}</dd>
          <dt>DocDate:</dt>
          <dd>
            {order.DocDate && new Date(order.DocDate).toLocaleDateString("de")}
          </dd>
          <dt>DocDueDate:</dt>
          <dd>
            {order.DocDueDate &&
              new Date(order.DocDueDate).toLocaleDateString("de")}
          </dd>
          <dt>CardCode:</dt>
          <dd>{order.CardCode}</dd>
          <dt>CardName:</dt>
          <dd>{order.CardName}</dd>
          <dt>NumAtCard:</dt>
          <dd>{order.NumAtCard}</dd>
        </dl>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ItemCode</TableCell>
              <TableCell>ItemDescription</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">OpenQty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.DocumentLines.map((dl) => (
              <TableRow
                key={dl.LineNum}
                className={
                  dl.RemainingOpenQuantity != 0 ? classes.differenceRow : ""
                }
              >
                <TableCell>{dl.ItemCode}</TableCell>
                <TableCell>{dl.ItemDescription}</TableCell>
                <TableCell align="right">{dl.Quantity}</TableCell>
                <TableCell align="right">{dl.RemainingOpenQuantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <pre>{JSON.stringify(order, null, "  ")}</pre> */}
      </CardContent>
      <CardActions>
        <Button onClick={handleBack}>Zurück</Button>
      </CardActions>
    </Card>
  );
};
