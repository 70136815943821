import { Link as RouterLink } from "react-router-dom";

import { Link } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";

export const DetailsCell: React.VFC<GridCellParams> = (params) => (
  <Link
    style={{
      textOverflow: "ellipsis",
      overflow: "hidden",
    }}
    component={RouterLink}
    to={`/${params.row.id}`}
    underline="hover"
  >
    <>{params.value || "..."}</>
  </Link>
);
