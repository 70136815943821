import React, { useMemo } from "react";

import CustomerIcon from "@mui/icons-material/AccountBox";
import SupplierIcon from "@mui/icons-material/AccountCircle";
import ProspectIcon from "@mui/icons-material/PersonPinCircle";

import { BusinessPartner } from "../../odata/api";

const BusinessPartnerAvatar: React.VFC<{ businessPartner: BusinessPartner }> =
  React.memo(({ businessPartner }) => {
    const cardType = useMemo(
      () => businessPartner.CardType,
      [businessPartner.CardType]
    );
    switch (cardType) {
      case "cCustomer":
        return <CustomerIcon />;
      case "cSupplier":
        return <SupplierIcon />;
      case "cLid":
        return <ProspectIcon />;
      default:
        return null;
    }
  });

export default BusinessPartnerAvatar;
