import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import {
  Alert,
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Link,
  SvgIcon,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import NewActivityIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import MenuIcon from "@mui/icons-material/Menu";

import { isTestDatabase } from "../odata/utils";
import LoginSection, { LoginSectionProps } from "./login/LoginSection";
import StartAnyDeskSessionDialog from "./dialogs/StartAnyDeskSessionDialog";

const pages = [
  { title: "Aktivitäten", target: "/" },
  { title: "zu liefernde Aufträge", target: "/orders" },
];

type AppBarProps = {
  isRefreshActive?: boolean;
  onRefreshClicked: () => void;
} & LoginSectionProps;

const AppBar: React.VFC<AppBarProps> = ({
  login,
  setLogin,
  isRefreshActive,
  onRefreshClicked,
}) => {
  const path = useLocation();
  const isRouteToNewActivity = path.pathname === "/new";
  const [openAnyDeskDialog, setOpenAnyDeskDialog] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <MuiAppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <Link
            component={RouterLink}
            color="inherit"
            variant="inherit"
            to={"/"}
            underline="hover"
          >
            HWWORK.APP
          </Link>
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                <Link
                  component={RouterLink}
                  color="inherit"
                  variant="inherit"
                  to={page.target}
                  underline="hover"
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page.title}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
            >
              <Link
                component={RouterLink}
                color="inherit"
                variant="inherit"
                to={page.target}
                underline="hover"
              >
                {page.title}
              </Link>
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {login && isTestDatabase(login) && (
            <Alert
              severity="warning"
              variant="filled"
              sx={{
                mx: { md: 8, sm: 2 },
                textAlign: "center",
                alignContent: "center",
                width: "inherit",
                whiteSpace: "nowrap",
              }}
            >
              HWSERVICE_TEST DB
            </Alert>
          )}
        </Box>
        <IconButton
          component={RouterLink}
          aria-label="Neue Aktivität"
          color="inherit"
          to={"/new"}
          disabled={isRouteToNewActivity}
          size="large"
        >
          <NewActivityIcon />
        </IconButton>
        <IconButton
          aria-label="Aktualisieren"
          color="inherit"
          onClick={onRefreshClicked}
          disabled={!isRefreshActive}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
        <IconButton
          onClick={() => setOpenAnyDeskDialog(true)}
          disabled={isRefreshActive}
        >
          <SvgIcon
            color="action"
            viewBox="0 0 1024 1024"
            titleAccess="AnyDesk Sitzung starten"
          >
            <g id="Icon">
              <circle
                cx="512"
                cy="512"
                r="512"
                style={{ fill: isRefreshActive ? "gray" : "#ef443b" }}
              />
              <path
                className="st1"
                d="m590.7 334.5-39.5 39.4 138.6 137.9-138.6 138.5 39.5 38.7L768 511.8z"
              />
              <path
                transform="rotate(-45.001 433.623 512.007)"
                className="st1"
                d="M308 386.4h251.2v251.2H308z"
              />
            </g>
          </SvgIcon>
        </IconButton>
        <LoginSection login={login} setLogin={setLogin} />
      </Toolbar>
      <StartAnyDeskSessionDialog
        open={openAnyDeskDialog}
        onAbort={() => {
          setOpenAnyDeskDialog(false);
        }}
      />
    </MuiAppBar>
  );
};

export default AppBar;
