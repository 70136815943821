import { Provider as JotaiProvider } from "jotai";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { useTheme } from "./theme";
import { Content } from "./Content";

const App: React.VFC = () => {
  const theme = useTheme();
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <JotaiProvider>
          <Router>
            <Content />
          </Router>
        </JotaiProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
