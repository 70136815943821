import { atom } from "jotai";
import { atomWithHash } from "jotai-location";
import { atomWithStorage } from "jotai/utils";

import {
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import {
  Activity,
  getActivities,
  getActivityTypes,
  login as loginF,
} from "../../odata/api";
import {
  getFilterValue,
  getSortValue,
  ODataGridColumn,
} from "../../odata/utils";
import {
  handledByState,
  isSmallWidthState,
  loginState,
  settingsState,
} from "../../state";
import { DetailsCell } from "../misc/Cells";

export const columnsTemplate: ODataGridColumn[] = (
  [
    {
      field: "CardCode",
      headerName: "ID",
      type: "string",
      hide: true,
    },
    {
      field: "Details",
      headerName: "Details",
      type: "string",
      width: 320,
      flex: 1,
      get filterField() {
        return "Details";
      },
      get sortField() {
        return "Details";
      },
      renderCell(params: any) {
        return DetailsCell(params);
      },
    },
    {
      field: "Priority",
      headerName: "Priorität",
      type: "string",
      width: 110,
      valueGetter(params: any) {
        return params.row.Priority;
      },
      valueFormatter(params: any) {
        return (params.value as string).replace(/^pr_/, "");
      },
      get filterField() {
        return "Priority";
      },
    },
    {
      field: "Type",
      headerName: "Typ",
      type: "string",
      width: 140,
      valueGetter(params: any) {
        return params.row.ActivityType2 && params.row.ActivityType2.Name;
      },
    },
    {
      field: "Customer",
      headerName: "Kundenname",
      type: "string",
      width: 140,
      valueGetter(params: any) {
        return (
          params.row.BusinessPartner && params.row.BusinessPartner.CardName
        );
      },
      get sortField() {
        return "CardCode";
      },
    },
    {
      field: "User",
      headerName: "Benutzer",
      type: "string",
      width: 140,
      valueGetter(params: any) {
        return params.row.User && params.row.User.UserName;
      },
      get sortField() {
        return "HandledBy";
      },
    },
    {
      field: "HandledBy",
      headerName: "Handled By",
      type: "number",
      hide: true,
      get filterField() {
        return "HandledBy";
      },
    },
    {
      field: "Closed",
      headerName: "Abgeschlossen",
      type: "boolean",
      hide: true,
      valueGetter(params: any) {
        return params.row.Closed && params.row.Closed === "tYES";
      },
      get filterField() {
        return "Closed";
      },
    },
    {
      field: "ActivityCode",
      headerName: "Activity Code",
      type: "number",
      width: 80,
      valueFormatter(params: any) {
        return params.value?.toLocaleString("de");
      },
      get filterField() {
        return "ActivityCode";
      },
      // get sortField() {
      //   return "ActivityCode";
      // },
    },
    {
      field: "ActivityDate",
      headerName: "Datum",
      type: "date",
      width: 120,
      valueGetter(params: GridValueGetterParams) {
        return params.row.ActivityDate;
      },
      valueFormatter(params: any) {
        return new Date(params.value as string).toLocaleString("de", {
          dateStyle: "medium",
        });
      },
      get filterField() {
        return "ActivityDate";
      },
      get sortField() {
        return "ActivityDate";
      },
    },
    {
      field: "ActivityTime",
      headerName: "Zeit",
      type: "time",
      hide: true,
      get sortField() {
        return "ActivityTime";
      },
    },
  ] as ODataGridColumn[]
).map((x) => ({
  ...x,
  filterable: !!x.filterField,
  sortable: !!x.sortField,
  editable: false,
}));

export const pageSizeState = atomWithStorage("activities-page-size", 20);

export const pageNumberState = atomWithHash("page-number", 0);

export const baseFilterModel: GridFilterModel = {
  items: [
    // { columnField: "HandledBy", operatorValue: "=", value: "14" },
    {
      field: "Closed",
      operator: "is",
      value: "false",
      id: Math.round(Math.random() * 10000),
    },
  ],
  logicOperator: GridLogicOperator.And,
};

export const baseSortModel: GridSortModel = [
  { field: "ActivityDate", sort: "desc" },
  { field: "ActivityTime", sort: "desc" },
];

export const filterModelState = atom<GridFilterModel>(baseFilterModel);

export const columnState = atom((get) => {
  const isSmallWidth = get(isSmallWidthState);
  return columnsTemplate.map((column) => {
    if (column.field === "Details")
      return { ...column, flex: isSmallWidth ? undefined : 1 };
    else return column;
  });
});

export const sortModelState = atom<GridSortModel>(baseSortModel);

export const activityTypes2State = atom(async (get) => {
  const login = get(loginState);
  if (!login) return [];
  try {
    const [, activityTypes] = await getActivityTypes(login);
    return activityTypes;
  } catch (error) {
    console.error(error);
    return [];
  }
});

export const activitiesState = atom(async (get) => {
  const login = get(loginState);
  const pageSize = get(pageSizeState);
  const pageNumber = get(pageNumberState);
  const filterModel = get(filterModelState);
  const sortModel = get(sortModelState);
  const handledBy = get(handledByState);
  const columns = get(columnState);
  const settings = get(settingsState);

  if (!login) throw new Error("Not logged in");

  await loginF(login);

  let $filter = getFilterValue(columns, filterModel);
  if (!settings.showAllUsers && handledBy)
    $filter += ` and HandledBy eq ${handledBy}`;
  const $orderby = getSortValue(columns, sortModel);

  const [totalCount, activities] = await getActivities(
    login,
    pageNumber,
    pageSize,
    {
      $filter,
      $orderby,
    }
  );
  const activititesWithId = activities.map((a) => ({
    ...a,
    id: a.ActivityCode,
  }));

  const data: [number, Activity[]] = [totalCount, activititesWithId];
  return data;
});
