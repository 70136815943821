import { atom, useAtom } from "jotai";
import { atomFamily } from "jotai/utils";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai/react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
} from "@mui/x-data-grid";

import { BusinessPartner } from "../../odata/api";
import { loginState } from "../../state";
import Activities from "../activities/Activities";

const filterModelState = atomFamily((cardCode?: string) =>
  atom<GridFilterModel>({
    items: [
      // { columnField: "HandledBy", operatorValue: "=", value: "14" },
      { field: "CardCode", operator: "equals", value: cardCode },
      // {
      //   columnField: "Closed",
      //   operatorValue: "is",
      //   value: "false",
      //   id: Math.round(Math.random() * 10000),
      // },
    ],
    logicOperator: GridLogicOperator.And,
  })
);

const sortModelState = atom<GridSortModel>([
  { field: "ActivityDate", sort: "desc" },
  // { field: "ActivityTime", sort: "desc" },
]);

type CustomerActivitiesDialogProps = {
  open: boolean;
  onClose: () => void;
  businessPartner?: Pick<BusinessPartner, "CardCode" | "CardName">;
};

const CustomerActivitiesDialog: React.VFC<CustomerActivitiesDialogProps> = ({
  businessPartner,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  useEffect(() => {
    onClose();
  }, [location, onClose]);

  const [login, setLogin] = useAtom(loginState);
  const filterModel = useAtomValue(filterModelState(businessPartner?.CardCode));
  const [sortModel, setSortModel] = useAtom(sortModelState);

  if (!login) return null;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={() => onClose()}
      fullScreen={isFullScreen}
    >
      <DialogTitle>
        Die letzten Aktivitäten des Kunden {businessPartner?.CardName}
      </DialogTitle>
      <DialogContent style={{ height: "80vh" }}>
        <Activities
          login={login}
          setLogin={setLogin}
          handledBy={undefined}
          refresh={0}
          onRefresh={() => {}}
          filterModel={filterModel}
          sortModel={sortModel}
          // setFilterModel={setFilterModel}
          setFilterModel={() => {}}
          setSortModel={setSortModel}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Schließen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerActivitiesDialog;
