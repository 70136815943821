import React from "react";

import { Typography } from "@mui/material";
import * as Sentry from "@sentry/react";

const ErrorBoundary: React.FC<{ children?: React.ReactNode }> = (props) => (
  <Sentry.ErrorBoundary
    fallback={() => <Typography>Leider ist ein Fehler aufgetreten.</Typography>}
  >
    {props.children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
