import { useAtom } from "jotai";
import React from "react";

import { LoginInfo } from "../../odata/api";
import { handledByState, settingsState } from "../../state";
import Activities from "../activities/Activities";
import { filterModelState, sortModelState } from "../activities/state";
import Page from "./Page";

export const ActivitiesPage: React.VFC<{
  login: LoginInfo;
  setLogin: (login: LoginInfo) => void;
  refresh: number;
  handleRefresh: () => void;
}> = ({ login, setLogin, refresh, handleRefresh }) => {
  const [settings, setSettings] = useAtom(settingsState);
  const [handledBy, setHandledBy] = useAtom(handledByState);

  const [filterModel, setFilterModel] = useAtom(filterModelState);
  const [sortModel, setSortModel] = useAtom(sortModelState);

  return (
    <Page title="hwwork.app">
      <Activities
        filterModel={filterModel}
        sortModel={sortModel}
        setFilterModel={setFilterModel}
        setSortModel={setSortModel}
        login={login}
        setLogin={setLogin}
        handledBy={settings?.showAllUsers ? undefined : handledBy}
        refresh={refresh}
        onRefresh={handleRefresh}
        setHandledBy={(handledBy) => {
          setHandledBy(handledBy);
          setSettings({
            ...settings,
            showAllUsers: !handledBy,
          });
        }}
      />
    </Page>
  );
};
