import React, { useMemo, useState } from "react";
import { shallow } from "zustand/shallow";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { Alert, AlertTitle } from "@mui/material";

import { LoginInfo } from "../../../odata/api";
import ArticleSelectionDialog from "../../dialogs/ArticleSelectionDialog";
import ServiceReportDialog from "../../dialogs/ServiceReportDialog";
import { useActivityStore } from "../state";
import ServiceReportInfo from "../ServiceReportInfo";

type OnSiteFieldsProps = {
  login: LoginInfo;
  serviceReportDialogOpen: boolean;
  confirmServiceDialogCreation: (data: {
    name: string;
    email: string;
    signature: string;
  }) => void;
  cancelServiceDialogCreation: () => void;
};

const OnSiteFields = React.memo(
  ({
    login,
    serviceReportDialogOpen,
    confirmServiceDialogCreation,
    cancelServiceDialogCreation,
  }: OnSiteFieldsProps) => {
    const [articleSelectionOpen, setArticleSelectionOpen] = useState(false);
    // const [serviceReportDialogOpen, setServiceReportDialogOpen] = useState(false);

    const {
      customerOrder,
      activity,
      addArticleToOrder,
      setOrderDocumentLineQuantity,
      deleteOrderDocumentLineSerialNumber,
      deleteOrderDocumentLine,
      // saveSignatureData,
    } = useActivityStore(
      (state) => ({
        customerOrder: state.order,
        activity: state.activity,
        addArticleToOrder: state.addArticleToOrder,
        setOrderDocumentLineQuantity: state.setOrderDocumentLineQuantity,
        deleteOrderDocumentLineSerialNumber:
          state.deleteOrderDocumentLineSerialNumber,
        deleteOrderDocumentLine: state.deleteOrderDocumentLine,
        saveSignatureData: state.saveSignatureData,
      }),
      shallow
    );

    const documentLines = useMemo(() => {
      return customerOrder?.DocumentLines || [];
    }, [customerOrder]);

    return (
      <Grid
        item
        xs={12}
        sm={12}
        style={{ overflowX: "hidden", width: "100px" }}
      >
        {!customerOrder && (
          <Alert severity="info" variant="outlined">
            <AlertTitle>Noch kein Kundenauftrag vorhanden.</AlertTitle>
            Durch das Hinzufügen eines Artikels und anschließendem Speichern
            wird automatisch ein Kundenauftrag angelegt.
          </Alert>
        )}
        <Card variant="outlined" style={{ overflow: "auto", width: "auto" }}>
          <CardHeader
            title={
              import.meta.env.DEV
                ? `Kundenauftrag (${customerOrder?.DocEntry || "Neu"})`
                : "Kundenauftrag"
            }
            subheader="Angaben für den Servicebericht"
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Toolbar disableGutters>
                  <Typography variant="h6">Artikelliste</Typography>
                  <IconButton
                    color="inherit"
                    onClick={() => setArticleSelectionOpen(true)}
                    title="Artikel verknüpfen"
                    size="large"
                  >
                    <AddIcon />
                  </IconButton>
                </Toolbar>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Artikelnr.</TableCell>
                      <TableCell>Beschreibung</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>S/N</TableCell>
                      <TableCell width="1" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerOrder &&
                      documentLines.map((documentLine) => (
                        <TableRow key={documentLine.LineNum}>
                          <TableCell>{documentLine.ItemCode}</TableCell>
                          <TableCell>{documentLine.ItemDescription}</TableCell>
                          <TableCell>
                            <TextField
                              variant="standard"
                              type="number"
                              inputProps={{
                                inputMode: "decimal",
                                min: 1,
                              }}
                              value={documentLine.Quantity}
                              disabled={documentLine.SerialNumbers.length > 0}
                              title={
                                documentLine.SerialNumbers.length > 0
                                  ? "Die Menge wird implizit erhöht, wenn man einen weiteren Artikel mit demselben Artikelcode hinzufügt, bzw. wenn man auf eine der Seriennummern klickt, wodurch sie entfernt werden."
                                  : undefined
                              }
                              onChange={(x) =>
                                setOrderDocumentLineQuantity(
                                  documentLine,
                                  Number(x.target.value)
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            {/* {documentLine.SerialNumbers.map(
                            (sn) => sn.InternalSerialNumber
                          ).join(", ")} */}
                            {documentLine.SerialNumbers.map((sn) => (
                              <Button
                                key={sn.InternalSerialNumber}
                                variant="text"
                                color="secondary"
                                title="Entfernen"
                                size="small"
                                endIcon={
                                  documentLine.SerialNumbers.length <=
                                  1 ? undefined : (
                                    <RemoveIcon />
                                  )
                                }
                                disabled={
                                  documentLine.SerialNumbers.length <= 1
                                }
                                onClick={() => {
                                  deleteOrderDocumentLineSerialNumber(
                                    documentLine,
                                    sn
                                  );
                                }}
                              >
                                {sn.InternalSerialNumber}
                              </Button>
                            ))}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => {
                                deleteOrderDocumentLine(documentLine);
                              }}
                              size="large"
                            >
                              <RemoveIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  {(!customerOrder?.DocumentLines ||
                    customerOrder?.DocumentLines?.length === 0) && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Alert severity="warning">
                            Noch keine Artikel verknüpft
                          </Alert>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
                {customerOrder && customerOrder.AttachmentEntry && (
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <ServiceReportInfo login={login} order={customerOrder} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ArticleSelectionDialog
          open={articleSelectionOpen}
          login={login}
          onConfirm={async (article, serialNumber) => {
            addArticleToOrder(customerOrder, article, serialNumber);
            setArticleSelectionOpen(false);
          }}
          onAbort={() => {
            setArticleSelectionOpen(false);
          }}
        />
        <ServiceReportDialog
          open={serviceReportDialogOpen}
          onConfirm={(data) => confirmServiceDialogCreation(data)}
          onAbort={() => cancelServiceDialogCreation()}
          activity={activity!}
          order={customerOrder!}
        />
      </Grid>
    );
  }
);

export default OnSiteFields;
