import React from "react";

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import { LOGIN_VERSION } from "../../constants";
import { decrypt, encrypt } from "../../crypto";
import { getUserInfo, login as loginF, LoginInfo } from "../../odata/api";
import AlertDialog from "../dialogs/AlertDialog";

type LoginFormProps = {
  setLogin?: (loginInfo: LoginInfo) => void;
};

const LoginForm: React.VFC<LoginFormProps> = ({ setLogin }) => {
  const [loading, setLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [database, setDatabase] = React.useState(
    import.meta.env.DEV ? "HWSERVICE_TEST" : "HWSERVICE"
  );
  const [invalidLoginDataDialogOpen, setInvalidLoginDataDialogOpen] =
    React.useState(false);

  const handleLogin = React.useCallback(async () => {
    let active = true;
    setLoading(true);

    try {
      const login = {
        username,
        password,
        database,
        version: LOGIN_VERSION,
      };

      const loginWithSession = await loginF(login);
      const loginWithUserInfo = await getUserInfo(loginWithSession);

      if (!active) return;

      setLogin && setLogin(loginWithUserInfo);

      return () => {
        active = false;
      };
    } catch {
      setInvalidLoginDataDialogOpen(true);
    }
    setLoading(false);
  }, [username, password, database, setLogin]);

  return (
    <Paper
      sx={{
        display: "flex",
        maxWidth: "400px",
        m: "auto",
        p: 2,
      }}
    >
      <Box
        component="form"
        noValidate
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: 1,
        }}
      >
        <FormControl fullWidth required variant="standard">
          {/* <InputLabel htmlFor="username">Benutzername</InputLabel> */}
          <TextField
            variant="standard"
            name="username"
            id="username"
            label="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoComplete="username"
          />
        </FormControl>
        <FormControl fullWidth required variant="standard">
          {/* <InputLabel htmlFor="password">Password</InputLabel> */}
          <TextField
            variant="standard"
            type="password"
            name="password"
            id="password"
            label="Password"
            value={decrypt(password)}
            onChange={(e) => setPassword(encrypt(e.target.value))}
            autoComplete="current-password"
          />
        </FormControl>
        <FormControl fullWidth required variant="standard">
          <InputLabel htmlFor="database">Datenbank</InputLabel>
          <Select
            variant="standard"
            name="database"
            id="database"
            value={database}
            onChange={(e) => setDatabase(e.target.value as string)}
          >
            <MenuItem value="HWSERVICE">HWSERVICE</MenuItem>
            <MenuItem value="HWSERVICE_TEST">HWSERVICE_TEST</MenuItem>
          </Select>
          <FormHelperText id="database-helper-text">
            Die SAP Datebank
          </FormHelperText>
        </FormControl>
        <Button
          color="primary"
          onClick={handleLogin}
          fullWidth
          disabled={loading}
        >
          Login
        </Button>
      </Box>
      <AlertDialog
        title="Fehler bei der Anmeldung"
        open={invalidLoginDataDialogOpen}
        onClose={() => setInvalidLoginDataDialogOpen(false)}
        disableCancel
      >
        Deine Logindaten scheinen ungültig zu sein.
      </AlertDialog>
    </Paper>
  );
};

export default LoginForm;
