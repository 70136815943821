import React from "react";
import { useParams } from "react-router-dom";

import { LoginInfo } from "../../odata/api";
import ActivityForm from "../activity/ActivityForm";
import Page from "./Page";

export const EditActivityPage: React.VFC<{ login: LoginInfo }> = ({
  login,
}) => {
  const { activityId } = useParams();
  return (
    <Page title={`hwwork.app: ${activityId}`}>
      <ActivityForm activityId={Number(activityId)} login={login} />
    </Page>
  );
};
