import { Alert, AlertTitle, Grid, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Activity } from "../../../odata/api";

const PreviousActivity = React.memo(
  ({ previousActivity }: { previousActivity?: Activity }) => {
    const navigate = useNavigate();

    if (!previousActivity) {
      return null;
    }

    const handleActivitySelection = (id: number | null) => {
      navigate(`/${id}`);
    };

    return (
      <Grid item xs={12} sm={12}>
        <Alert title="Vorherige Aktivität vorhanden" severity="info">
          Gehe zur vorherigen Aktivität:{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() =>
              handleActivitySelection(previousActivity.ActivityCode)
            }
          >
            {previousActivity.Details} (
            {previousActivity.BusinessPartner?.CardName})
          </Link>
        </Alert>
      </Grid>
    );
  }
);

export default PreviousActivity;
