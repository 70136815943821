import React from "react";

import {
  Box,
  FormControlLabel,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid";

import { LoginInfo } from "../../odata/api";
import ActivitiesGrid from "./ActivitiesGrid";
import ActivitiesList from "./ActivitiesList";

type ActivitiesProps = {
  login: LoginInfo;
  setLogin: (login: LoginInfo) => void;
  handledBy?: number;
  refresh: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  setFilterModel: (filterModel: GridFilterModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  onRefresh: () => void;
  setHandledBy?: (n?: number) => void;
};

const Activities: React.VFC<ActivitiesProps> = ({
  login,
  setLogin,
  handledBy,
  refresh,
  filterModel,
  sortModel,
  setFilterModel,
  setSortModel,
  onRefresh,
  setHandledBy,
}) => {
  const theme = useTheme();
  const isSmallWidth = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      component="div"
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">Aktivitäten</Typography>
        <FormControlLabel
          label="Zeige alle Benutzer"
          control={
            <Switch
              checked={!handledBy}
              onChange={(e) => {
                setHandledBy &&
                  (e.target.checked
                    ? setHandledBy(undefined)
                    : setHandledBy(login?.userInfo?.internalKey));
              }}
            />
          }
        />
      </div>
      {isSmallWidth ? (
        <ActivitiesList
          filterModel={filterModel}
          sortModel={sortModel}
          login={login}
          setLogin={setLogin}
          handledBy={handledBy}
          refresh={refresh}
          onRefresh={onRefresh}
        />
      ) : (
        <ActivitiesGrid
          filterModel={filterModel}
          sortModel={sortModel}
          setFilterModel={setFilterModel}
          setSortModel={setSortModel}
          login={login}
          setLogin={setLogin}
          handledBy={handledBy}
          refresh={refresh}
        />
      )}
    </Box>
  );
};

export default Activities;
